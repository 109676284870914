import * as React from "react"
const SvgComponent = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="#f26930"
        stroke="#f26930"
        viewBox="0 0 470 470"
    >
        <path d="M452.159 16.363H17.841C8.003 16.363 0 24.366 0 34.204v329.205c0 9.838 8.003 17.841 17.841 17.841h163.807v57.387h-70.739c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h248.182c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-70.738V381.25H452.16c9.838 0 17.841-8.003 17.841-17.841V34.204c-.001-9.838-8.004-17.841-17.842-17.841zM273.353 438.637h-76.705V381.25h76.705v57.387zM455 363.409c0 1.54-1.301 2.841-2.841 2.841H17.841c-1.54 0-2.841-1.301-2.841-2.841V34.204c0-1.54 1.301-2.841 2.841-2.841h434.318c1.54 0 2.841 1.301 2.841 2.841v329.205z" />
        <path d="M431.478 305.228H38.523c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h392.955c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5zM124.666 250.416c33.343 19.503 71.496 29.812 110.334 29.812 38.839 0 76.991-10.309 110.334-29.812 32.35-18.921 59.488-46.004 78.483-78.319a7.502 7.502 0 0 0 0-7.602c-18.995-32.315-46.134-59.398-78.483-78.32C311.991 66.672 273.839 56.363 235 56.363s-76.992 10.309-110.334 29.812c-32.35 18.922-59.489 46.005-78.483 78.32a7.502 7.502 0 0 0 0 7.602c18.994 32.315 46.133 59.398 78.483 78.319zm107.413 14.764c-52.103-1.55-94.011-44.413-94.011-96.884 0-52.472 41.909-95.335 94.011-96.885.974-.014 1.945-.048 2.921-.048.976 0 1.947.034 2.921.048 52.102 1.55 94.011 44.413 94.011 96.885 0 52.471-41.909 95.333-94.011 96.884-.974.014-1.945.048-2.921.048s-1.947-.034-2.921-.048zm176.517-96.884c-23.162 37.494-57.726 66.025-97.629 82.126 22.103-20.46 35.965-49.702 35.965-82.126S333.07 106.63 310.966 86.17c39.903 16.1 74.468 44.632 97.63 82.126zM159.034 86.169c-22.104 20.461-35.966 49.703-35.966 82.127 0 32.423 13.862 61.665 35.966 82.126-39.903-16.101-74.467-44.631-97.629-82.126 23.161-37.495 57.725-66.026 97.629-82.127z" />
        <path d="M284.918 168.295a49.52 49.52 0 0 0-4.423-20.536 7.5 7.5 0 1 0-13.668 6.178 34.63 34.63 0 0 1 3.091 14.358c0 19.254-15.664 34.918-34.918 34.918s-34.918-15.664-34.918-34.918 15.664-34.918 34.918-34.918c4.989 0 9.82 1.04 14.359 3.092a7.5 7.5 0 0 0 6.178-13.668A49.517 49.517 0 0 0 235 118.377c-27.525 0-49.918 22.394-49.918 49.918s22.393 49.918 49.918 49.918c27.524 0 49.918-22.394 49.918-49.918z" />
    </svg>
)
export default SvgComponent
