import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
    name: yup
        .string()
        .required(VALIDATION.required),
    rate: yup
        .string()
        .required(VALIDATION.required),
})
