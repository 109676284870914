import React, {useEffect, useRef, useState} from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap'
import {
  EditOutlined,
  FilePresentOutlined,
  SaveAsOutlined,
  ViewInArOutlined,
} from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import Image from 'react-bootstrap/Image'
import PayrollTabs from '../PayrollTabs'
import {useParams} from "react-router-dom";
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {
  IEmployee,
  ISalaryDetailsToAllowance,
  ISalaryDetailsToDeduction,
  ISalaryList,
  IAllowance,
  IDeduction,
  INonRecurringAllowanceList, INonRecurringDeduction, INonRecurringDeductionList, ISalary, IWorksite, IPayrollTabs,
  ILocationAndImages, IAttendanceLocationImage, IAttendanceData, IAttendanceList, IBranch
} from "./types";
import {API_ENDPOINT, ERROR_MESSAGE, IMAGE_BASE_URL, SUCCESS_MESSAGE, TEXT} from "../../../../utils/globalConstant";
import Pagination from 'components/Pagination'
import Delete from "asset/SvgComponent/Delete";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import './style.css'
import fs from 'fs';
import Alert from "components/Alert";
import Select from "components/ReactSelect";
import PersonIcon from "asset/SvgComponent/PersonIcon";
import {faCameraRetro} from "@fortawesome/free-solid-svg-icons/faCameraRetro";
import Webcam from "react-webcam";
import PrintIcon from "asset/SvgComponent/Print";
import ReactToPrint from "react-to-print";
import PrintAttendancePayrollComponent from "./printAttendancePayrollComponent";
import PrintPayslipComponent from "./printPayslipComponent"
import {Avatar} from "@mui/material";
import Stack from "@mui/material/Stack";
import Write from "asset/SvgComponent/Write";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import moment from "moment/moment";

export default function Index() {
  const componentRef = useRef<HTMLDivElement>(null);
  const componentRef2 = useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState(false)
  const [fileName, setFileName] = React.useState('Upload Boundary File')
  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [showSuccess, setShowSuccess] = React.useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)
  const [key, setKey] = useState('cutoff-table')
  const {id} = useParams();
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(6)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)
  const [data, setData] = React.useState<ISalary[]>([]);
  const [allowances, setAllowances] = React.useState([{allowance_id: 0,
    allowance_name: "",
    limit_per_month: ""}])
  const [deductions, setDeductions] = React.useState([{deduction_id: 0,
    deduction_name: "",
    limit_per_month: ""}])
  const [nonRecurringAllowances, setNonRecurringAllowances] = React.useState([{id: 0,
    allowance_name: ""}])
  const [nonRecurringDeductions, setNonRecurringDeductions] = React.useState([{id: 0,
    deduction_name: ""}])
  const [showDeduction, setShowDeduction] = React.useState(false)
  const [showAllowance, setShowAllowance] = React.useState(false)
  const [recurringDeductionDetails, setRecurringDeductionDetails] = React.useState({deduction_id: 0, deduction_name: "", amount: 0})
  const [recurringAllowanceDetails, setRecurringAllowanceDetails] = React.useState({allowance_id: 0, allowance_name: "", amount: 0})
  const [employeeNonRecurringDeductions, setEmployeeNonRecurringDeductions] = React.useState([{id:0, employee_id:0, payroll_id:0, deduction_id: 0, deduction_name: "", amount:0}])
  const [employeeNonRecurringAllowances, setEmployeeNonRecurringAllowances] = React.useState([{id:0, employee_id:0, payroll_id:0, allowance_id: 0, allowance_name: "", amount:0}])
  const [selectedPayrollDetails, setSelectedPayrollDetails] = React.useState({employee_id: 0, payroll_id: 0});
  const [payslipData, setPayslipData] = React.useState<ISalary | null>(null)
  const [entries, setEntries] = useState(10);
  const [dropdownWorksite, setDropdownWorksite] = useState(0);
  const [worksites, setWorksites] = useState<IWorksite[]>([])
  const [dropdownWorksiteName, setDropdownWorksiteName] = useState('');
  const [grandTotalNetPay, setGrandTotalNetPay] = useState(0);
  const [payrollTitle, setPayrollTitle] = useState('');
  const [payrollType, setPayrollType] = useState('');
  const [selectedWorksiteOptionOverview, setSelectedWorksiteOptionOverview] = useState<{value: number, label: string}>({value: 0, label: "All Worksites"})
  const [worksiteOptions, setWorksiteOptions] = useState<{value: number, label: string}[]>([]);
  const [payrollTabs, setPayrollTabs] = useState<IPayrollTabs>({cutoffTab: "",
    ratesTab: "",
    earningsTab: "",
    allowancesTab: "",
    deductionsTab: ""});
  const [searched, setSearched] = useState('')
  const [showAttendance, setShowAttendance] = useState(false)
  const [employeeName, setEmployeeName] = useState("")
  const handleShowAttendance = () => setShowAttendance(true);
  const handleCloseAttendance = () => setShowAttendance(false)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)
  const [attendanceDetails, setAttendanceDetails] = useState({
    employee_id: 0,
    payroll_id: 0,
  })

  const [computationGuideCurrentPage, setComputationCurrentPage] = useState(1)

  const [attendance, setAttendance] = useState<IAttendanceList[]>([])
  const [showAllImage, setShowAllImage] = useState(false)
  const [attendanceImageLocation, setAttendanceImageLocation] = useState<ILocationAndImages[]>([]);
  const [selectedBranchOption, setSelectedBranchOption] = useState<{value: number, label: string}>({value: 0, label: "All Branches"})
  const [branchOptions, setBranchOptions] = useState<{value: number, label: string}[]>([]);
  const [dropdownBranch, setDropdownBranch] = useState(0);
  const [dropdownBranchName, setDropdownBranchName] = useState('');
  const [taxDetails, setTaxDetails] = useState({
    name: "",
    de_minimis: 0,
    taxable_compensation: 0,
    compensation_level: 0,
    taxable_income_excess: 0,
    taxable_income_tax: 0,
    base_tax: 0,
    withholding_tax: 0
  })
  const [payslipLogo, setPayslipLogo] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [showDetails, setShowDetails] = useState(false);
  const handleShowDetails = () => {
    setShowDetails(true)
  }
  const handleCloseDetails = () => {
    setShowDetails(false)
  }
  const [showComputationGuide, setShowComputationGuide] = useState(false)
  const handleShowComputationGuide = () => setShowComputationGuide(true)
  const handleCloseComputationGuide = () => setShowComputationGuide(false)

  const computationGuideData1 = [
    {no: 1,name: "Ordinary Day",rate: "1",percentage: 100},
    {no: 2,name: "Sunday or rest day",rate: "1.3",percentage: 130},
    {no: 3,name: "Special (non-working) day",rate: "1.3",percentage: 130},
    {no: 4,name: "Special (non-working) day falling on rest day",rate: "1.5",percentage: 150},
    {no: 5,name: "Double special (non-working) day",rate: "1.5",percentage: 150},
    {no: 6,name: "Double special (non-working) day falling on rest day",rate: "1.95",percentage: 195},
    {no: 7,name: "Regular holiday",rate: "2",percentage: 200},
    {no: 8,name: "Regular holiday falling on rest day",rate: "2.6",percentage: 260},
    {no: 9,name: "Double Regular holiday",rate: "3",percentage: 300},
    {no: 10,name: "Double Regular holiday falling on rest day",rate: "3.9",percentage: 390},
    {no: 11,name: "Ordinary day, night shift",rate: "1 * 1.1 = 1.1",percentage: 110},
    {no: 12,name: "Rest day, night shift",rate: "1.3 * 1.1 = 1.43",percentage: 143},
    {no: 13,name: "Special (non-working) day, night shift",rate: "1.3 * 1.1 = 1.43",percentage: 143},
    {no: 14,name: "Special (non-working day), rest day, night shift",rate: "1.5 * 1.1 = 1.65",percentage: 165},
    {no: 15,name: "Double special (non-working day), night shift",rate: "1.5 * 1.1 = 1.65",percentage: 165},
  ];

  const computationGuideData2 = [
    {no: 16,name: "Double special (non-working day), rest day, night shift",rate: "1.95 * 1.1 = 2.145",percentage: 214.5},
    {no: 17,name: "Regular holiday, night shift",rate: "2 * 1.1 = 2.2",percentage: 220},
    {no: 18,name: "Regular holiday, rest day, night shift",rate: "2.6 * 1.1 = 2.86",percentage: 286},
    {no: 19,name: "Double holiday, night shift",rate: "3 * 1.1 = 3.3",percentage: 330},
    {no: 20,name: "Double holiday, rest day, night shift",rate: "3.9 * 1.1 = 4.29",percentage: 429},
    {no: 21,name: "Ordinary day, overtime (OT)",rate: "1 * 1.25 = 1.25",percentage: 125},
    {no: 22,name: "Rest day, (OT)",rate: "1.3 * 1.3 = 1.69",percentage: 169},
    {no: 23,name: "Special (non-working), OT",rate: "1.3 * 1.3 = 1.69",percentage: 169},
    {no: 24,name: "Special (non-working) day, rest day, OT",rate: "1.5 * 1.3 = 1.95",percentage: 195},
    {no: 25,name: "Double special (non-working) day, OT",rate: "1.5 * 1.3 = 1.95",percentage: 195},
    {no: 26,name: "Double special (non-working) day, rest day, OT",rate: "1.95 * 1.3 = 2.535",percentage: 253.5},
    {no: 27,name: "Regular holiday, OT",rate: "2 * 1.3 = 2.6",percentage: 260},
    {no: 28,name: "Regular holiday, rest day, OT",rate: "2.6 * 1.3 = 3.38",percentage: 338},
    {no: 29,name: "Double holiday, OT",rate: "3 * 1.3 = 3.9",percentage: 390},
    {no: 30,name: "Double holiday, rest day, OT",rate: "3.9 * 1.3 = 5.07",percentage: 507},
  ];

  const computationGuideData3 = [
    {no: 31,name: "Ordinary day, night shift, OT",rate: "1 * 1.1 * 1.25",percentage: 125},
    {no: 32,name: "Rest day, night shift, OT",rate: "1.3 * 1.1 * 1.3 = 1.859",percentage: 185.9},
    {no: 33,name: "Special (non-working) day, night shift, OT",rate: "1.3 * 1.1 * 1.3 = 1.859",percentage: 185.9},
    {no: 34,name: "Special (non-working) day, rest day, night shift, OT",rate: "1.5 * 1.1 * 1.3 = 2.145",percentage: 214.5},
    {no: 35,name: "Double special (non-working) day, night shift, OT",rate: "1.5 * 1.1 * 1.3 = 2.145",percentage: 214.5},
    {no: 36,name: "Double special (non-working) day, rest day, night shift, OT",rate: "1.95 * 1.1 * 1.3 = 2.7885",percentage: 278.85},
    {no: 37,name: "Regular holiday, night shift, OT",rate: "2 * 1.1 * 1.3 = 2.86",percentage: 286},
    {no: 38,name: "Regular holiday, rest day, night shift, OT",rate: "2.6 * 1.1 * 1.3 = 3.718",percentage: 371.8},
    {no: 39,name: "Double holiday, night shift, OT",rate: "3 * 1.1 * 1.3 = 4.29",percentage: 429},
    {no: 40,name: "Double holiday, rest day, night shift, OT",rate: "3.9 * 1.1 * 1.3 = 5.577",percentage: 557.7}
  ];
  const [computationGuide, setComputationGuide] = useState(computationGuideData1)

  const handleChangeComputationGuidePage = (page: number) => {
    if(page == 1){
      setComputationGuide(computationGuideData1)
    }else if(page == 2){
      setComputationGuide(computationGuideData2)
    }else if(page == 3){
      setComputationGuide(computationGuideData3)
    }
  }

  const handleCloseAllImage = () => {
    setShowAllImage(false);
    setShowAttendance(true)
  }
  const handleShowAllImage = async() => {
    setShowAllImage(true);
    setShowAttendance(false)
    try {
      const res = await axios.get<IAPI_Response<ILocationAndImages[]>>(API_ENDPOINT.GET_ATTENDANCE_IMAGE_LOCATION_FOR_JVD_FOR_PAYROLL, {
        params: {
          employee_id: attendanceDetails.employee_id,
          payroll_id: id
        },
      })
      const result = await res

      setAttendanceImageLocation([])
      if (result?.data?.results) {
        setAttendanceImageLocation(result?.data?.results)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);

  }

  const getPayslipLogo = async () => {
    try {
      const response = await axios.get<IAPI_Response<{payslipLogo: string}>>(API_ENDPOINT.GET_PAYSLIP_LOGO)
      const result = await response

      if(result?.data?.results){
        setPayslipLogo(result?.data?.results.payslipLogo)
      }


    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);

  }

  const getCompanyName = async () => {
    try {
      const response = await axios.get<IAPI_Response<{companyName: string}>>(API_ENDPOINT.GET_COMPANY_NAME)
      const result = await response

      if(result?.data?.results){
        setCompanyName(result?.data?.results.companyName)
      }


    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);

  }

  const handlePrint = () => {
    console.log("print ")
  }

  const handleViewTaxDetails = (salary_details_id: number) => {
    data.map(salary => {
      if(salary.salary_details_id == salary_details_id){
        setTaxDetails({
          name: salary.employee.last_name + ", " + salary.employee.first_name,
          de_minimis: salary.de_minimis,
          taxable_compensation: salary.taxable_compensation,
          compensation_level: salary.compensation_level,
          taxable_income_excess: salary.taxable_income_excess,
          taxable_income_tax: salary.taxable_income_tax,
          base_tax: salary.base_tax,
          withholding_tax: salary.withholding_tax
        })
      }
    })
    handleShowDetails()
  }


  const handleViewAttendance = (id:number, salary_details_id: number, employee_name: string) => {
    getAttendanceByUser(null, id, salary_details_id, 10);
    handleShowAttendance()
    setAttendanceDetails({employee_id: id, payroll_id: salary_details_id})
    setEmployeeName(employee_name)
  }

  const handleChangeDropdownWorksite = (selectedOption:any) => {
    setDropdownWorksite(selectedOption.value);
    setDropdownWorksiteName(selectedOption.label)
    setSelectedWorksiteOptionOverview(selectedOption)

    if(selectedOption.value == "0"){
      setDropdownWorksiteName('')
    }

    getSalaries(null, entries, selectedOption.value, searched)
  }

  const handleChangeDropdownBranch = (selectedOption:any) => {
    setDropdownBranch(selectedOption.value);
    setDropdownBranchName(selectedOption.label)
    setSelectedBranchOption(selectedOption)

    if(selectedOption.value == "0"){
      setDropdownBranchName('')
    }

    getSalaries(null, entries, selectedOption.value, searched)
  }

  const handleChangeEntries = (e:any) => {
    setEntries(e.target.value);
    getSalaries(null, e.target.value, dropdownBranch, searched)
  }

  const getAttendanceByUser = async (
      page_number: null | number,
      employee_id: null | number,
      payroll_id: null | number,
      entries: 10
  ) => {

    try {
      const res = await axios.get<IAPI_Response<IAttendanceData>>(API_ENDPOINT.GET_ATTENDANCE_BY_USER_FOR_MFBC_FOR_PAYROLL, {
        params: {
          page: page_number,
          employee_id: employee_id,
          payroll_id: payroll_id,
          entries: 10
        },
      })
      const result = await res

      setAttendance([])
      if (result?.data?.results?.data?.length) {
        setAttendance(result?.data?.results?.data)
        setCurrentPage1(result?.data?.results?.current_page)
        setLastPage1(result?.data?.results?.last_page)
        setPerPage1(result?.data?.results?.per_page)
        setItemCount1(result?.data?.results?.total)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleShowPayslip = async(id: any) => {
    try {
      const response = await axios.get<IAPI_Response<ISalary>>(API_ENDPOINT.SHOW_PAYSLIP+"/"+id)
      const result = await response

      if (result?.data?.results) {
        setPayslipData(result?.data?.results)
        setShow(true)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);

  }

  const handleAmountChangeOnAllowance = (e:any) => {
    setRecurringAllowanceDetails({...recurringAllowanceDetails, amount: e.target.value})
  }

  const handleChangeAllowance = (e:any) => {
    nonRecurringAllowances.map(allowance => {
      if(allowance.id == e.target.value){
        setRecurringAllowanceDetails({...recurringAllowanceDetails, allowance_id: e.target.value, allowance_name: allowance.allowance_name})
      }
    })

  }

  const handleAddAllowance = () => {
    setEmployeeNonRecurringAllowances(current => [...current, {id:0, employee_id:0, payroll_id:0, allowance_id: recurringAllowanceDetails.allowance_id, allowance_name: recurringAllowanceDetails.allowance_name, amount: recurringAllowanceDetails.amount}])
    setRecurringAllowanceDetails({allowance_id: 0, allowance_name: "", amount: 0})
  }

  const handleDeductionNameChange = (e:any) => {

    nonRecurringDeductions.map(deduction => {
      if(deduction.id == e.target.value){
        setRecurringDeductionDetails({...recurringDeductionDetails, deduction_id: e.target.value, deduction_name: deduction.deduction_name})
      }
    })
  }

  const handleAmountChange = (e:any) => {
    setRecurringDeductionDetails({...recurringDeductionDetails, amount: e.target.value})
  }

  const handleAddDeduction = () => {
    setEmployeeNonRecurringDeductions(current => [...current, {id:0, employee_id:0, payroll_id:0, deduction_id: recurringDeductionDetails.deduction_id, deduction_name: recurringDeductionDetails.deduction_name, amount: recurringDeductionDetails.amount}])
    setRecurringDeductionDetails({deduction_id: 0, deduction_name: "", amount: 0})
  }

  const handleShowDeduction = (id: any) => {
    data.map(salary => {
      if(salary.salary_details_id == id){
        setEmployeeNonRecurringDeductions(salary.non_recurring_deduction)
        setSelectedPayrollDetails({employee_id: salary.employee_id, payroll_id: salary.payroll_id})
      }
    })
    getNonRecurringDeductions();
    setShowDeduction(true);
  }

  const handleShowAllowance = (id: any) => {
    data.map(salary => {
      if(salary.salary_details_id == id){
        setEmployeeNonRecurringAllowances(salary.non_recurring_allowance)
        setSelectedPayrollDetails({employee_id: salary.employee_id, payroll_id: salary.payroll_id})
      }
    })
    getNonRecurringAllowances();
    setShowAllowance(true);
  }

  const handleCloseAllowance = () => {
    setShowAllowance(false)
  }

  const handleCloseDeduction = () => {
    setShowDeduction(false)
  }

  const handleSelect = (e: any) => {
    setKey(e)
  }

  const handleDeleteDeduction = (deduction_name:string) => {
    setEmployeeNonRecurringDeductions((current) =>
        current.filter((deduction) => deduction.deduction_name !== deduction_name)
    );
  }

  const handleDeleteAllowance = (id:any) => {
    setEmployeeNonRecurringAllowances((current) =>
        current.filter((allowance) => allowance.allowance_id !== id)
    );
  }

  const handleChangeSearch = (e:any) => {
    setSearched(e.target.value)
    getSalaries(null, entries, dropdownBranch, e.target.value)
  }

  const getAllBranch = async () => {
    try {
      const res = await axios.get<IAPI_Response<IBranch[]>>(API_ENDPOINT.ALL_BRANCH)
      const result = await res
      if (result?.data?.results?.length) {
        const branches = result?.data?.results;
        setBranchOptions([])
        setBranchOptions(current => [...current, {value: 0, label: "All Branches"}]);
        branches.map(branch => {
          setBranchOptions(current => [...current, {value: branch.branch_id, label: branch.branch_name}]);
        })
      }else{
        setBranchOptions([])
        setBranchOptions(current => [...current, {value: 0, label: "All Branches"}]);
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getWorkSite = async () => {

    try {
      const response = await axios.get<IAPI_Response<IWorksite[]>>(API_ENDPOINT.ALL_WORK_SITE)
      const result = await response
      if (result?.data?.results?.length) {
        setWorksites(result?.data?.results)
        const worksites = result?.data?.results;
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Worksite"}]);
        worksites.map(worksite => {
          setWorksiteOptions(current => [...current, {value: worksite.id, label: worksite.work_site_name}]);
        })
      }else{
        setWorksites([])
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Worksite"}]);
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleExportPayroll = async() => {

    if(dropdownBranch != 0) {
      try {
        const response = await axios.get(API_ENDPOINT.EXPORT_PAYROLL_FOR_MFBC + "/" + id + "/" + dropdownBranch, {
          headers: {
            'Content-Type': 'blob'
          },
          responseType: 'arraybuffer'
        })
        const outputFilename = `${dropdownWorksiteName} Payroll (${data[0].month_of_salary}).xls`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();

        FileSaver.writeFileSync(outputFilename, response.data);

      } catch {
        // setShowError(true)
        // setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }
    }else{
      setShowError(true)
      setError("Please choose worksite.")
    }


    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getNonRecurringDeductions = async () => {
    try {
      const response = await axios.get<IAPI_Response<INonRecurringDeductionList[]>>(API_ENDPOINT.GET_NON_RECURRING_DEDUCTION)
      const result = await response

      if (result?.data?.results?.length) {
        setNonRecurringDeductions(result?.data?.results)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getNonRecurringAllowances = async () => {
    try {
      const response = await axios.get<IAPI_Response<INonRecurringAllowanceList[]>>(API_ENDPOINT.GET_NON_RECURRING_ALLOWANCE)
      const result = await response

      if (result?.data?.results?.length) {
        setNonRecurringAllowances(result?.data?.results)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handlePostAllowance = async() => {
    const params = {
      employee_id: selectedPayrollDetails.employee_id,
      payroll_id: selectedPayrollDetails.payroll_id,
      allowances: employeeNonRecurringAllowances
    }
    await axios.post(API_ENDPOINT.POST_ALLOWANCE_PAYROLL, params).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.BRANCH.CREATED)
        setShowAllowance(false)

        getSalaries(null, entries, dropdownBranch, searched)
        setRecurringAllowanceDetails({allowance_id: 0, allowance_name: "", amount: 0})
      }

    }).catch(error => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handlePostDeduction = async() => {
    const params = {
      employee_id: selectedPayrollDetails.employee_id,
      payroll_id: selectedPayrollDetails.payroll_id,
      deductions: employeeNonRecurringDeductions
    }
    await axios.post(API_ENDPOINT.POST_DEDUCTION_PAYROLL, params).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.BRANCH.CREATED)
        setShowDeduction(false)

        getSalaries(null, entries, dropdownBranch, searched)
        setRecurringDeductionDetails({deduction_id: 0, deduction_name: "", amount: 0})
      }

    }).catch(error => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getAllowances = async () => {
    try {
      const response = await axios.get<IAPI_Response<IAllowance[]>>(API_ENDPOINT.ALL_ALLOWANCE)
      const result = await response

      if (result?.data?.results?.length) {
        setAllowances(result?.data?.results)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getDeductions = async () => {
    try {
      const response = await axios.get<IAPI_Response<IDeduction[]>>(API_ENDPOINT.ALL_DEDUCTION)
      const result = await response

      if (result?.data?.results?.length) {
        setDeductions(result?.data?.results)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getPayrollTabsSetup = async () => {

    try {
      const response = await axios.get<IAPI_Response<IPayrollTabs>>(API_ENDPOINT.GET_PAYROLL_TABS)
      const result = await response

      if(result?.data?.results){
        setPayrollTabs(result?.data?.results)
      }


    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getSalaries = async (page_number: null | number, entries: null | number, branch: null | number, searchedValue: null | string) => {

    const clientProfile = localStorage.getItem('clientProfile');

      try {
        const response = await axios.get<IAPI_Response<ISalaryList>>(API_ENDPOINT.GET_SALARIES_FOR_MFBC+"/"+id+"/"+entries+"/"+branch, {
          params: {
            page: page_number,
            searched: searchedValue
          },

        })
        const result = await response

        if (result?.data?.results?.data?.length) {
          setData(result?.data?.results?.data)
          setCurrentPage(result?.data?.results?.current_page)
          setLastPage(result?.data?.results?.last_page)
          setPerPage(result?.data?.results?.per_page)
          setItemCount(result?.data?.results?.total)
          setFrom(result?.data?.results?.from)
          setGrandTotalNetPay(result?.data?.results?.total_net_pay)
          setPayrollTitle(result?.data?.results?.payroll_title)
          setPayrollType(result?.data?.results?.payroll_type)
        }else{
          setData([])
          setGrandTotalNetPay(0)
        }

      } catch {
        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }


  useEffect(() => {
    ;(async () => {
      getAllowances()
      getDeductions()
      getSalaries(null, entries, dropdownBranch, searched)
      getWorkSite()
      getPayrollTabsSetup()
      getAllBranch()
      getPayslipLogo()
      getCompanyName()
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Payroll List</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/payroll-range'>
            Payroll
          </Breadcrumb.Item>
          <Breadcrumb.Item href='/payroll/generate-payroll'>Generate Payroll</Breadcrumb.Item>
          <Breadcrumb.Item active>Payroll List</Breadcrumb.Item>
        </Breadcrumb>
        <Card className='border-0  p-0 bordered-card  expanded-container'>
          <Card.Body className='p-0'>
            <Row className='mt-3'>
              <Col sm={3}>
              </Col>
              <Col sm={3}>
                <Form.Group style={{textAlign:'center'}}>
                  <h5 style={{fontSize: '95%'}}><b>{payrollTitle}</b></h5>
                </Form.Group>

              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className='w-25 input-g'>
                  <Form.Control
                      aria-label='search'
                      aria-describedby='inputGroup-sizing-sm'
                      placeholder='Search...'
                      onChange={handleChangeSearch}
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                    onClick={handleExportPayroll}
                    className=' add-new-btn rounded mx-3 text-white'
                    variant='warning'
                >
                  <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='17'
                      viewBox='0 0 20.055 20.207'
                  >
                    <g id='Edit-Icon' transform='translate(0.5 0.652)'>
                      <g id='Icon_feather-edit' data-name='Icon feather-edit'>
                        <path
                            id='Path_44'
                            data-name='Path 44'
                            d='M11.523,6H4.894A1.894,1.894,0,0,0,3,7.894V21.152a1.894,1.894,0,0,0,1.894,1.894H18.152a1.894,1.894,0,0,0,1.894-1.894V14.523'
                            transform='translate(-3 -3.991)'
                            fill='none'
                            stroke='#fff'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                        />
                        <path
                            id='Path_45'
                            data-name='Path 45'
                            d='M21.943,3.406a2.009,2.009,0,1,1,2.841,2.841l-9,9L12,16.191l.947-3.788Z'
                            transform='translate(-6.318 -2.818)'
                            fill='none'
                            stroke='#fff'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                        />
                      </g>
                    </g>
                  </svg>
                  &nbsp;Export CSV
                </Button>{' '}
              </Col>
            </Row>
            <Row className='mt-3' style={{marginLeft: '1px', marginRight: '1px'}}>
              {/*<Col sm={2}>*/}
              {/*  <Form.Group style={{textAlign:'center'}}>*/}
              {/*    <Form.Label column="sm">Worksite:</Form.Label>*/}
              {/*  </Form.Group>*/}
              {/*</Col>*/}
              <Col sm={2}>
                {/*<span className='fw-bold mx-3'>No.</span>*/}
                <Form.Group>
                  <Form.Label className='fw-bold form-labels'>Branch:</Form.Label>
                  <Select
                      name="branch_id"
                      value={selectedBranchOption}
                      options={branchOptions}
                      onChange={handleChangeDropdownBranch}
                  />
                </Form.Group>
              </Col>
              <Col sm={8}></Col>
              <Col sm={2}>
                <span className='fw-bold mx-3'>  &nbsp;</span>
                <Form.Group>
                  {/*<Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>*/}
                  <Button
                      onClick={() => handleShowComputationGuide()}
                      variant='primary'
                      className='p-4 pt-2 pb-2 text-white rounded'
                      style={{ fontSize: '12px'}}
                      size='sm'
                      title={'Computation Guide'}
                  >
                    Show Computation Guide
                  </Button>
                </Form.Group>
              </Col>


              {/*<Col sm={1}>*/}
              {/*  <Form.Group style={{textAlign:'center'}}>*/}
              {/*    <Form.Label column="sm">Entries:</Form.Label>*/}
              {/*  </Form.Group>*/}
              {/*</Col>*/}

            </Row>
            <br />

            {/* SCROLLABLE WRAPPER*/}
            <div className='wrapper'>
              {/* ROW TO MAKE TABS INLINED*/}
              <div>
                <Tabs
                  variant={'tabs'}
                  className='tabs p-0 payroll-tabs'
                  style={{ marginLeft: '272px', width: '700px' }}
                  // style={{marginLeft: '385px', width: '670px'}}
                  // id="tabs-payroll"
                  activeKey={key}
                  onSelect={(e) => handleSelect(e)}
                >
                  {payrollTabs.cutoffTab == "1" ? <Tab className='p-0 text-dark' eventKey='cutoff-table' title='Cut-off'></Tab> : ""}
                  {payrollTabs.ratesTab == "1" ? <Tab className='p-0 text-dark' eventKey='rates-table' title='Rates'></Tab> : ""}
                  {payrollTabs.earningsTab == "1" ? <Tab className='p-0 text-dark' eventKey='total-earnings-table' title='Earnings'></Tab> : ""}
                  {payrollTabs.allowancesTab == "1" ? <Tab className='p-0 text-dark' eventKey='allowance-table' title='Allowances'></Tab> : ""}
                  {payrollTabs.deductionsTab == "1" ? <Tab className='p-0 text-dark' eventKey='deductions-table' title='Deductions'></Tab> : ""}

                  <br />
                </Tabs>
              </div>
              <Table className=''>
                <thead>
                  <tr>
                    <th
                      className='text-center bordered-tabs-top'
                      style={{ width: '30px', maxWidth: '30px' }}
                    >
                      #
                    </th>
                    <th
                      className='text-center bordered-tabs-top'
                      style={{ width: '130px', maxWidth: '130px' }}
                    >
                      Employee
                    </th>
                    {/*<th*/}
                    {/*  className='text-center bordered-tabs-top'*/}
                    {/*  style={{*/}
                    {/*    width: '65px',*/}
                    {/*    maxWidth: '65px',*/}
                    {/*    whiteSpace: 'nowrap',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  Payroll <br /> Period*/}
                    {/*</th>*/}
                    {/*<th*/}
                    {/*  className='text-center bordered-tabs-top'*/}
                    {/*  style={{ width: '61px', maxWidth: '61px' }}*/}
                    {/*>*/}
                    {/*  Compensation*/}
                    {/*</th>*/}
                    <th
                      className='text-center bordered-tabs-top'
                      style={{ width: '91px', maxWidth: '91px' }}
                    >
                      Monthly
                    </th>
                    {/* CUTOFF*/}
                    {key == 'cutoff-table' ? (
                      <>
                        <th className='text-center bordered-tabs-left'
                            style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}
                        >Cut-off
                        </th>
                        <th className=' text-center ' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>
                          Days <br /> Rendered
                        </th>
                        <th className=' text-center bordered-tabs-top-xxl' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>
                          Absences/ <br />
                          Suspensions
                        </th>
                        <th className=' text-center  bordered-tabs-top-xxl' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>Late (Mins.)</th>
                        <th className=' text-center  bordered-tabs-top-xxl' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>Leave</th>
                        <th className=' text-center  bordered-tabs-top-xxl' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>Special Holiday</th>
                        <th className=' text-center  bordered-tabs-top-xxl' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>Regular Holiday</th>
                        <th className=' text-center  bordered-tabs-top-xxl' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>Night Shift (Hrs.)</th>
                        <th className=' text-center  bordered-tabs-top-xxl' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>Overtime (Hrs.)</th>
                        <th className=' text-center  bordered-tabs-top-xxl bordered-tabs-right' style={{ width: '80px', maxWidth: '80px', fontSize: '90%' }}>
                          Undertime  <br/> (Mins.)
                        </th>
                      </>
                    ) : (
                      ''
                    )}

                    {/* RATES*/}
                    {key == 'rates-table' ? (
                      <>
                        <th
                          className='text-center bordered-tabs-left'
                          style={{ width: '114px', maxWidth: '114px' }}
                        >
                          Daily
                        </th>
                        <th
                          className=' text-center'
                          style={{
                            width: '114px',
                            maxWidth: '114px',
                          }}
                        >
                          Special <br /> Holiday
                        </th>
                        <th
                          className=' text-center '
                          style={{
                            width: '114px',
                            maxWidth: '114px',
                          }}
                        >
                          Regular <br /> Holiday
                        </th>
                        <th className=' text-center  bordered-tabs-top-xxl ' style={{ width: '114px', maxWidth: '114px' }}>
                          Rate/ <br />
                          Hour
                        </th>
                        <th className=' text-center  bordered-tabs-top-xxl ' style={{ width: '114px', maxWidth: '114px' }}>
                          Overtime <br /> Rate
                        </th>
                        <th className=' text-center  bordered-tabs-top-xxl ' style={{ width: '114px', maxWidth: '114px' }}>
                          Night <br /> Diff <br /> Rate
                        </th>
                        <th className=' text-center  bordered-tabs-top-xxl  bordered-tabs-right' style={{ width: '114px', maxWidth: '114px' }}>
                          Five Days <br /> Incentive
                        </th>
                      </>
                    ) : (
                      ''
                    )}

                    {/* ALLOWANCE*/}
                    {key == 'allowance-table' ? (
                      <>
                        {
                          allowances.map((allowance, index) => (
                              <th
                                  className={index == 0 ? "text-center bordered-tabs-left" : "text-center"}
                                  style={{ width: 560/allowances.length + "px", maxWidth: 560/allowances.length + "px" }}
                                  key={index}
                              >
                                {allowance.allowance_name}
                              </th>
                          ))
                        }

                        <th className=' text-center bordered-tabs-top-xxl' style={{ width: '120px', maxWidth: '120px' }}>
                          Misc
                        </th>

                        <th
                            className=' text-center  bordered-tabs-right'
                            style={{ width: '120px', maxWidth: '120px' }}
                        >
                          Total <br />
                          Allowance
                        </th>
                      </>
                    ) : (
                      ''
                    )}

                    {/* DEDUCTIONS*/}
                    {key == 'deductions-table' ? (
                      <>
                        {
                          deductions.map((deduction, index) => (
                              <th
                                  className={index == 0 ? "text-center bordered-tabs-left" : "text-center"}
                                  style={{ width: 420/deductions.length + "px", maxWidth: 420/deductions.length + "px", fontSize: '85%' }}
                                  key={index}
                              >
                                {deduction.deduction_name}
                              </th>
                          ))
                        }
                        <th className=' text-center bordered-tabs-top-xxl' style={{ width: '70px', maxWidth: '70px', fontSize: '85%' }}>Late</th>
                        {/*<th className=' text-center bordered-tabs-top-xxl' style={{ width: '70px', maxWidth: '70px', fontSize: '90%' }}>Absent</th>*/}
                        <th className=' text-center bordered-tabs-top-xxl' style={{ width: '70px', maxWidth: '70px', fontSize: '85%' }}>
                          Undertime
                        </th>
                        <th className=' text-center bordered-tabs-top-xxl' style={{ width: '70px', maxWidth: '70px', fontSize: '85%' }}>
                          Tax Withheld
                        </th>
                        <th className=' text-center bordered-tabs-top-xxl' style={{ width: '90px', maxWidth: '90px', fontSize: '85%' }}>
                          &nbsp; Misc &nbsp;
                        </th>
                        <th
                            className=' text-center  bordered-tabs-right'
                            style={{ width: '80px', maxWidth: '80px', fontSize: '85%' }}
                        >
                          Total <br />
                          Deduction
                        </th>
                      </>
                    ) : (
                      ''
                    )}

                    {/* TOTAL EARNINGS*/}
                    {key == 'total-earnings-table' ? (
                      <>
                        <th className=' text-center bordered-tabs-left' style={{ width: '100px', maxWidth: '100px' }}>
                          Basic <br /> Pay
                        </th>
                        <th className=' text-center' style={{ width: '100px', maxWidth: '100px' }}>
                          Leave
                        </th>
                        <th
                          className=' text-center '
                          style={{
                            width: '100px',
                            maxWidth: '100px',
                          }}
                        >
                          Special <br /> Holiday
                        </th>
                        <th
                          className=' text-center '
                          style={{
                            width: '100px',
                            maxWidth: '100px',
                          }}
                        >
                          Regular <br /> Holiday
                        </th>
                        <th className=' text-center bordered-tabs-top-xxl' style={{ width: '100px', maxWidth: '100px' }}>Overtime</th>
                        <th className=' text-center ' style={{ width: '100px', maxWidth: '100px' }} >
                          Night <br /> Diff
                        </th>

                        <th className=' text-center bordered-tabs-top-xxl' style={{ width: '100px', maxWidth: '100px' }}>
                          Adjustments <br />
                          (+/-)
                        </th>
                        <th className=' text-center bordered-tabs-top-xxl bordered-tabs-right' style={{ width: '100px', maxWidth: '100px' }}>
                          Total Earnings
                        </th>
                      </>
                    ) : (
                      ''
                    )}

                    <th className='text-center bordered-tabs-top' style={{minWidth: '64px'}}>Net Pay</th>
                    <th
                      className='text-center bordered-tabs-top'
                      style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                {
                  data.length > 0 ?
                      data.map((salary, index) => (
                          <tr key={index}>
                            <td style={{ width: '35px', maxWidth: '35px', fontSize:'90%' }}>{index + from}</td>
                            <td
                                className='text-truncate fw-bold employee-name'
                                style={{ width: '145px', maxWidth: '145px' }}
                                onClick={() => handleViewAttendance(salary.employee.employee_id, salary.payroll_id, salary.employee.first_name + " " + salary.employee.last_name)}
                            >
                              <Stack direction='row' spacing={1}>
                                <div>
                                  {
                                    salary.employee.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ salary.employee.photo}
                                            className={"profile-avatar"}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                            className={"profile-avatar"}
                                        ></Avatar>
                                  }
                                </div>
                                <div>{salary.employee.last_name}, {salary.employee.first_name}</div>
                              </Stack>
                            </td>
                            {/*<td*/}
                            {/*    className='px-0 pe-0 text-left'*/}
                            {/*    style={{*/}
                            {/*      width: '70px',*/}
                            {/*      maxWidth: '70px',*/}
                            {/*      whiteSpace: 'nowrap',*/}
                            {/*      fontSize: '11.3px',*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*  {salary.month_of_salary}*/}
                            {/*</td>*/}
                            {/*<td className=' text-right' style={{ width: '79px', maxWidth: '79px' }}>*/}
                            {/*  {Number(salary.total_basic).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                            {/*</td>*/}
                            <td className=' text-right' style={{ width: '79px', maxWidth: '79px' }}>
                              {Number(salary.basic_salary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>

                            {key == 'cutoff-table' ? (
                                <>
                                  <td className='text-right bordered-tabs-left' style={{fontSize: '90%'}}>{salary.total_working_days}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{salary.total_days_rendered}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{salary.total_absence}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{salary.total_late}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{salary.total_leave}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{salary.total_special_holiday}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{salary.total_regular_holiday}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{salary.total_night_shift}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{salary.total_over_time_hour}</td>
                                  <td className='text-right bordered-tabs-right' style={{fontSize: '90%'}}>{salary.total_undertime_hours}</td>
                                </>
                            ) : (
                                ''
                            )}

                            {key == 'rates-table' ? (
                                <>
                                  <td className='text-right bordered-tabs-left' style={{fontSize: '90%'}}>{Number(salary.per_day_salary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.special_holiday_rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.regular_holiday_rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.hourly_rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.overtime_rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.night_diff_rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right bordered-tabs-right' style={{fontSize: '90%'}}>{Number(salary.five_days_incentive).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </>
                            ) : (
                                ''
                            )}

                            {key == 'allowance-table' ? (
                                <>
                                  {
                                    allowances.map((allowance, index) => (
                                        salary.salary_details_to_allowance.some(elem => elem.allowance_id == allowance.allowance_id) ?
                                          salary.salary_details_to_allowance.map((employeeAllowance, i) => (
                                              allowance.allowance_id == employeeAllowance.allowance_id ?
                                                  <td style={{fontSize: '90%'}} className={index == 0 ? "text-right bordered-tabs-left":"text-right"}>{Number(employeeAllowance.amount_of_allowance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                  : ""
                                          )) : <td style={{fontSize: '90%'}} className={index == 0 ? "text-right bordered-tabs-left":"text-right"}>{Number(0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    ))
                                  }
                                  <td className='text-right' style={{fontSize: '90%'}}>
                                    {Number(salary.total_non_recurring_allowance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    <Button
                                        onClick={() => handleShowAllowance(salary.salary_details_id)}
                                        className=' p-1 pt-0 pb-0'
                                        style={{ fontSize: '12px', color: 'white' }}
                                        variant=' btn-transparent'
                                        size='sm'
                                        title={'Add Non-recurring Allowance'}
                                    >
                                      <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='16.143'
                                          height='16.143'
                                          viewBox='0 0 16.143 16.143'
                                      >
                                        <g id='New' transform='translate(0.5 0.5)'>
                                          <g
                                              id='Icon_feather-plus-circle'
                                              data-name='Icon feather-plus-circle'
                                              transform='translate(-3 -3)'
                                          >
                                            <path
                                                id='Path_46'
                                                data-name='Path 46'
                                                d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'
                                                fill='none'
                                                stroke='#a7a9ac'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                            <path
                                                id='Path_47'
                                                data-name='Path 47'
                                                d='M18,12v6.057'
                                                transform='translate(-7.429 -4.457)'
                                                fill='none'
                                                stroke='#f26930'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                            <path
                                                id='Path_48'
                                                data-name='Path 48'
                                                d='M12,18h6.057'
                                                transform='translate(-4.457 -7.429)'
                                                fill='none'
                                                stroke='#f26930'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </Button>
                                    ({salary.non_recurring_allowance.length})
                                  </td>
                                  <td style={{fontSize: '90%'}} className='text-right bordered-tabs-right'>{Number(salary.total_allowance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </>
                            ) : (
                                ''
                            )}

                            {key == 'deductions-table' ? (
                                <>
                                  {
                                    deductions.map((deduction, index) => (
                                        salary.salary_details_to_deduction.some(elem => elem.deduction_id == deduction.deduction_id) ?
                                          salary.salary_details_to_deduction.map((employeeDeduction, i) => (
                                              deduction.deduction_id == employeeDeduction.deduction_id ?
                                                  <td style={{fontSize: '85%'}} className={index == 0 ? "text-right bordered-tabs-left":"text-right"}>({Number(employeeDeduction.amount_of_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</td> : ""
                                          )) : <td style={{fontSize: '85%'}} className={index == 0 ? "text-right bordered-tabs-left":"text-right"}>({Number(0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</td>
                                    ))
                                  }
                                  <td className='text-right' style={{fontSize: '85%'}}>({Number(Number(salary.total_late_amount)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</td>
                                  {/*<td className='text-right'>{Number(Number(salary.total_absence_amount)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>*/}
                                  <td className='text-right' style={{fontSize: '85%'}}>({Number(salary.total_undertime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</td>
                                  <td className='text-right taxable-details' style={{fontSize: '85%'}} onClick={() => handleViewTaxDetails(salary.salary_details_id)}>({Number(salary.withholding_tax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</td>
                                  <td className='text-right' style={{fontSize: '85%'}}>
                                    {Number(salary.total_non_recurring_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    <Button
                                        onClick={() => handleShowDeduction(salary.salary_details_id)}
                                        className=' p-1 pt-0 pb-0'
                                        style={{ fontSize: '10px', color: 'white' }}
                                        variant=' btn-transparent'
                                        size='sm'
                                        title={'Add Non-recurring Deduction'}
                                    >
                                      <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='16.143'
                                          height='16.143'
                                          viewBox='0 0 16.143 16.143'
                                      >
                                        <g id='New' transform='translate(0.5 0.5)'>
                                          <g
                                              id='Icon_feather-plus-circle'
                                              data-name='Icon feather-plus-circle'
                                              transform='translate(-3 -3)'
                                          >
                                            <path
                                                id='Path_46'
                                                data-name='Path 46'
                                                d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'
                                                fill='none'
                                                stroke='#a7a9ac'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                            <path
                                                id='Path_47'
                                                data-name='Path 47'
                                                d='M18,12v6.057'
                                                transform='translate(-7.429 -4.457)'
                                                fill='none'
                                                stroke='#f26930'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                            <path
                                                id='Path_48'
                                                data-name='Path 48'
                                                d='M12,18h6.057'
                                                transform='translate(-4.457 -7.429)'
                                                fill='none'
                                                stroke='#f26930'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </Button>
                                    ({salary.non_recurring_deduction.length})
                                  </td>
                                  <td className='text-right bordered-tabs-right' style={{fontSize: '85%'}}>({Number(salary.total_deduction - salary.total_absence_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</td>
                                </>
                            ) : (
                                ''
                            )}

                            {key == 'total-earnings-table' ? (
                                <>
                                  <td className='text-right bordered-tabs-left' style={{fontSize: '90%'}}>{Number(salary.net_pay_without_two_hours).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.total_leave_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.total_special_day_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.total_regular_holiday_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.total_overtime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.night_diff_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right' style={{fontSize: '90%'}}>{Number(salary.adjustment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                  <td className='text-right bordered-tabs-right' style={{fontSize: '90%'}}>{Number(Number(salary.total_earnings)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </>
                            ) : (
                                ''
                            )}
                            <td className='fw-bold text-right' style={{minWidth: '64px'}}>{Number(salary.net_salary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            <td className='text-center' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                              <Button
                                  onClick={() => handleShowPayslip(salary.salary_details_id)}
                                  className=' p-1 pt-0 pb-0'
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='11.5'
                                    height='11.5'
                                    viewBox='0 0 18.746 13.906'
                                >
                                  <g id='View' transform='translate(0.5 0.5)'>
                                    <path
                                        id='Path_21'
                                        data-name='Path 21'
                                        d='M1.5,12.453S4.726,6,10.373,6s8.873,6.453,8.873,6.453-3.226,6.453-8.873,6.453S1.5,12.453,1.5,12.453Z'
                                        transform='translate(-1.5 -6)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_22'
                                        data-name='Path 22'
                                        d='M18.34,15.92a2.42,2.42,0,1,1-2.42-2.42A2.42,2.42,0,0,1,18.34,15.92Z'
                                        transform='translate(-7.047 -9.467)'
                                        fill='none'
                                        stroke='#f26930'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                  </g>
                                </svg>
                              </Button>
                              <Button
                                  onClick={handleShowEdit}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='11'
                                    height='11'
                                    viewBox='0 0 16.143 16.268'
                                >
                                  <g id='Edit' transform='translate(0.5 0.627)'>
                                    <g
                                        id='Icon_feather-edit'
                                        data-name='Icon feather-edit'
                                        transform='translate(-3 -2.818)'
                                    >
                                      <path
                                          id='Path_44'
                                          data-name='Path 44'
                                          d='M9.773,6H4.505A1.505,1.505,0,0,0,3,7.505V18.04a1.505,1.505,0,0,0,1.505,1.505H15.04a1.505,1.505,0,0,0,1.505-1.505V12.773'
                                          transform='translate(0 -1.586)'
                                          fill='none'
                                          stroke='#a7a9ac'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          strokeWidth='1'
                                      />
                                      <path
                                          id='Path_45'
                                          data-name='Path 45'
                                          d='M19.9,3.286a1.6,1.6,0,0,1,2.258,2.258L15.01,12.692,12,13.445l.753-3.01Z'
                                          transform='translate(-4.485 0)'
                                          fill='none'
                                          stroke='#f26930'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          strokeWidth='1'
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </Button>
                              <Button
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='11'
                                    height='11'
                                    viewBox='0 0 16.141 16.139'
                                >
                                  <g id='Download' transform='translate(0.5 0.5)'>
                                    <path
                                        id='Path_59'
                                        data-name='Path 59'
                                        d='M19.64,22.5v2.9a1.58,1.58,0,0,1-1.682,1.451H6.182A1.58,1.58,0,0,1,4.5,25.4V22.5'
                                        transform='translate(-4.5 -11.714)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_60'
                                        data-name='Path 60'
                                        d='M10.5,15l4.667,3.628L19.834,15'
                                        transform='translate(-7.598 -8.802)'
                                        fill='none'
                                        stroke='#f26930'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_61'
                                        data-name='Path 61'
                                        d='M18,13.208V4.5'
                                        transform='translate(-10.43 -4.5)'
                                        fill='none'
                                        stroke='#f26930'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                  </g>
                                </svg>
                              </Button>
                              <Button
                                  className='p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px' }}
                                  variant='transparent'
                                  size='sm'
                              >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='11'
                                    height='11'
                                    viewBox='0 0 16.141 16.139'
                                >
                                  <g id='Print' transform='translate(0.5 0.5)'>
                                    <path
                                        id='Path_62'
                                        data-name='Path 62'
                                        d='M9,7.572V3h9.915V7.572'
                                        transform='translate(-6.387 -3)'
                                        fill='none'
                                        stroke='#f26930'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_63'
                                        data-name='Path 63'
                                        d='M6.028,19.378H4.514A1.422,1.422,0,0,1,3,18.072V14.806A1.422,1.422,0,0,1,4.514,13.5H16.626a1.422,1.422,0,0,1,1.514,1.306v3.266a1.422,1.422,0,0,1-1.514,1.306H15.112'
                                        transform='translate(-3 -7.606)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_64'
                                        data-name='Path 64'
                                        d='M9,21h8.933v5.225H9Z'
                                        transform='translate(-5.896 -11.085)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                  </g>
                                </svg>
                              </Button>
                              <Button
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='11'
                                    height='11'
                                    viewBox='0 0 16.141 17.822'
                                >
                                  <g id='Share' transform='translate(0.5 0.5)'>
                                    <path
                                        id='Path_68'
                                        data-name='Path 68'
                                        d='M12.885,20.265l5.745,3.348'
                                        transform='translate(-8.182 -10.584)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_69'
                                        data-name='Path 69'
                                        d='M18.621,9.765l-5.736,3.348'
                                        transform='translate(-8.182 -5.972)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_65'
                                        data-name='Path 65'
                                        d='M27.547,5.523A2.523,2.523,0,1,1,25.023,3,2.523,2.523,0,0,1,27.547,5.523Z'
                                        transform='translate(-12.406 -3)'
                                        fill='none'
                                        stroke='#f26930'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_66'
                                        data-name='Path 66'
                                        d='M9.547,16.023A2.523,2.523,0,1,1,7.023,13.5,2.523,2.523,0,0,1,9.547,16.023Z'
                                        transform='translate(-4.5 -7.612)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_67'
                                        data-name='Path 67'
                                        d='M27.547,26.523A2.523,2.523,0,1,1,25.023,24,2.523,2.523,0,0,1,27.547,26.523Z'
                                        transform='translate(-12.406 -12.225)'
                                        fill='none'
                                        stroke='#f26930'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                  </g>
                                </svg>
                              </Button>

                              <Button
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='11'
                                    height='11'
                                    viewBox='0 0 15.598 17.221'
                                >
                                  <g id='Delete' transform='translate(0.5 0.5)'>
                                    <path
                                        id='Path_23'
                                        data-name='Path 23'
                                        d='M4.5,9H19.1'
                                        transform='translate(-4.5 -5.756)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_24'
                                        data-name='Path 24'
                                        d='M18.854,6.244V17.6a1.622,1.622,0,0,1-1.622,1.622H9.122A1.622,1.622,0,0,1,7.5,17.6V6.244m2.433,0V4.622A1.622,1.622,0,0,1,11.555,3H14.8a1.622,1.622,0,0,1,1.622,1.622V6.244'
                                        transform='translate(-5.879 -3)'
                                        fill='none'
                                        stroke='#a7a9ac'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_25'
                                        data-name='Path 25'
                                        d='M15,16.5v4.866'
                                        transform='translate(-9.322 -9.201)'
                                        fill='none'
                                        stroke='#f26930'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                    <path
                                        id='Path_26'
                                        data-name='Path 26'
                                        d='M21,16.5v4.866'
                                        transform='translate(-12.078 -9.201)'
                                        fill='none'
                                        stroke='#f26930'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1'
                                    />
                                  </g>
                                </svg>
                              </Button>
                            </td>
                            <br />
                          </tr>
                      )) :
                      <tr><td colSpan={15} className="text-center">No Data</td></tr>
                }


                  {/* LAST HIDDEN ROW FOR SHADOW TOP ONLY*/}
                  <tr className='hidden-table'>
                    <td
                      className='text-truncate border-bottom-0'
                      style={{ width: '30px', maxWidth: '0px' }}
                    >
                      &nbsp;
                    </td>
                    <td
                      className='text-truncate border-bottom-0'
                      style={{ width: '150px', maxWidth: '150px' }}
                    >
                      &nbsp;
                    </td>
                    {/*<td*/}
                    {/*  className='px-0  border-bottom-0 pe-0 '*/}
                    {/*  style={{*/}
                    {/*    width: '70px',*/}
                    {/*    maxWidth: '70px',*/}
                    {/*    whiteSpace: 'nowrap',*/}
                    {/*    fontSize: '11px',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  &nbsp;*/}
                    {/*</td>*/}

                    {/*<td*/}
                    {/*  className='text-truncate text-right border-bottom-0'*/}
                    {/*  style={{ width: '60px', maxWidth: '60px' }}*/}
                    {/*></td>*/}
                    <td
                      className='text-truncate text-right border-bottom-0'
                      style={{ width: '86px', maxWidth: '86px' }}
                    >
                      &nbsp;
                    </td>
                    {key == 'cutoff-table' ? (
                      <>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                      </>
                    ) : (
                      ''
                    )}

                    {key == 'rates-table' ? (
                      <>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                      </>
                    ) : (
                      ''
                    )}

                    {key == 'allowance-table' ? (
                      <>
                        {
                          allowances.map((allowance, index) => (
                              <td className='text-right bordered-tabs-bottom border-bottom-0' key={index}>&nbsp;</td>
                          ))
                        }
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                      </>
                    ) : (
                      ''
                    )}

                    {key == 'deductions-table' ? (
                      <>
                        {
                          deductions.map((deduction, index) => (
                              <td className='text-right bordered-tabs-bottom border-bottom-0' key={index}>&nbsp;</td>
                          ))
                        }
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        {/*<td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>*/}
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                      </>
                    ) : (
                      ''
                    )}

                    {key == 'total-earnings-table' ? (
                      <>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                        <td className='text-right bordered-tabs-bottom border-bottom-0'>&nbsp;</td>
                      </>
                    ) : (
                      ''
                    )}
                    <td className='fw-bold text-right border-bottom-0'>&nbsp;</td>
                    <td
                      className='text-center border-bottom-0'
                      style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
                    >
                      <Button
                        className=' p-1 pt-0 pb-0'
                        style={{ fontSize: '12px', color: 'white' }}
                        variant=' btn-transparent'
                        size='sm'
                      >
                        &nbsp;
                      </Button>
                      <Button
                        className=' p-1 pt-0 pb-0'
                        style={{ fontSize: '12px', color: 'white' }}
                        variant=' btn-transparent'
                        size='sm'
                      >
                        &nbsp;
                      </Button>
                      <Button
                        className=' p-1 pt-0 pb-0'
                        style={{ fontSize: '12px', color: 'white' }}
                        variant=' btn-transparent'
                        size='sm'
                      >
                        &nbsp;
                      </Button>
                      <Button
                        className='p-1 pt-0 pb-0'
                        style={{ fontSize: '12px' }}
                        variant='transparent'
                        size='sm'
                      >
                        &nbsp;
                      </Button>
                      <Button
                        className=' p-1 pt-0 pb-0'
                        style={{ fontSize: '12px', color: 'white' }}
                        variant=' btn-transparent'
                        size='sm'
                      >
                        &nbsp;
                      </Button>

                      <Button
                        className=' p-1 pt-0 pb-0'
                        style={{ fontSize: '12px', color: 'white' }}
                        variant=' btn-transparent'
                        size='sm'
                      >
                        &nbsp;
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <Row className="mb-3">
              <Col sm={4}>
                <Row>
                  <Col sm={5}>
                    <Pagination
                        itemsCount={itemsCount}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        lastPage={lastPage}
                        setCurrentPage={setCurrentPage}
                        alwaysShown={false}
                        pageClicked={(page: number) => {
                          getSalaries(page, entries, dropdownBranch, searched);
                        }
                        }
                    />
                  </Col>
                  <Col sm={5} className='d-flex justify-content-end hidden'>
                    <Form.Group>
                      <Form.Select
                          size={'sm'}
                          name="work_shift_id"
                          value={entries}
                          onChange={handleChangeEntries}
                          // onBlur={handleBlur}
                          // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                      >
                        <option value="10" selected>10</option>
                        <option value="25" >25</option>
                        <option value="50" >50</option>
                        <option value="100" >100</option>

                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

              </Col>
              <Col sm={4}>
              </Col>
              <Col sm={2} className='fw-bold d-flex justify-content-end hidden'>
                <Form.Group>
                  <Form.Label className='text-right net-pay-label' column="sm">Total Net Pay:</Form.Label>
                </Form.Group>
              </Col>
              <Col sm={1} className='fw-bold d-flex'>
                <Form.Group>
                  <Form.Label column="sm" className="net-pay-label text-left">{Number(Number(grandTotalNetPay)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Form.Label>
                </Form.Group>
              </Col>

            </Row>
            
          </Card.Body>
        </Card>
        {/* PAYSLIP MODAL*/}
        <Modal className='modal' show={show} size={'lg'} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              {/* <Image*/}
              {/*    style={{*/}
              {/*        width: '10%'*/}
              {/*    }}*/}
              {/*    src=*/}
              {/*        "/Octaneware1.png"*/}

              {/* />*/}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col sm={6} style={{ borderRight: 'solid #C0C0C0 1px' }}>
                  <Row>
                    <Col sm={6}>
                      <Row>
                        <Col sm={6} style={{margin: 'auto'}}>
                          <Image
                              style={{
                                width: '90%',
                              }}
                              src={payslipLogo != "" ? process.env.REACT_APP_IMAGE_URL+ payslipLogo: "/Octaneware1.png"}
                          />
                        </Col>
                        <Col sm={6} style={{margin: 'auto'}}>
                          <h5 className='fw-bold'>{companyName}</h5>
                        </Col>
                      </Row>

                    </Col>
                    <Col sm={6} className=' d-grid justify-content-end ' style={{margin: 'auto'}}>
                      <h5 className='fw-bold'>Payslip</h5>
                    </Col>
                  </Row>
                  <hr />
                  {/*<Row className='mb-3'>*/}
                  {/*  <Col sm={6}>*/}
                  {/*    <h6 className='fw-bold'>Employee ID :</h6>*/}
                  {/*  </Col>*/}
                  {/*  <Col sm={6}>*/}
                  {/*    <h6>{payslipData?.employee.employee_id}</h6>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Employee Name :</h6>
                    </Col>
                    <Col sm={6} >
                      <h6>{payslipData?.employee?.first_name} {payslipData?.employee.last_name}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Position :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.designation}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Department :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.department}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Worksite :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.worksite}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Per Cut Off</h6>
                    </Col>
                    <Col sm={6} >
                      <h6>{Number(payslipData?.total_working_days).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} days</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Days rendered</h6>
                    </Col>
                    <Col sm={6} >
                      <h6>{Number(payslipData?.total_present).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} days</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Payroll Period :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.month_of_salary}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Payable Date :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6></h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Payable Type:</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.payrollType}</h6>
                    </Col>
                  </Row>
                  {/*<Row className='mb-3'>*/}
                  {/*  <Col sm={6}>*/}
                  {/*    <h6 className='fw-bold'>Payment Method :</h6>*/}
                  {/*  </Col>*/}
                  {/*  <Col sm={6}>*/}
                  {/*    <h6>Cash</h6>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                  <br/>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h5 className='fw-bold'>Net Pay :</h5>
                    </Col>
                    <Col sm={6}>
                      <h5 className='fw-bold'>{Number(payslipData?.net_salary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <ReactToPrint
                      trigger={() =>
                          <Button
                              className=' mb-2 add-new-btn rounded me-1 text-white'
                              variant='info'

                          >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16.14'
                                height='15.14'
                                viewBox='0 0 16.14 15.14'
                            >
                              <g
                                  id='Icon_feather-printer'
                                  data-name='Icon feather-printer'
                                  transform='translate(-2.5 -3.5)'
                              >
                                <path
                                    id='Path_62'
                                    data-name='Path 62'
                                    d='M9,7.572V3h9.915V7.572'
                                    transform='translate(-3.388 1)'
                                    fill='none'
                                    stroke='#fff'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_63'
                                    data-name='Path 63'
                                    d='M6.028,19.378H4.514A1.422,1.422,0,0,1,3,18.072V14.806A1.422,1.422,0,0,1,4.514,13.5H16.626a1.422,1.422,0,0,1,1.514,1.306v3.266a1.422,1.422,0,0,1-1.514,1.306H15.112'
                                    transform='translate(0 -4.606)'
                                    fill='none'
                                    stroke='#fff'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_64'
                                    data-name='Path 64'
                                    d='M9,21h8.88v5.225H9Z'
                                    transform='translate(-2.87 -8.085)'
                                    fill='none'
                                    stroke='#fff'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                              </g>
                            </svg>
                            &nbsp;Print
                          </Button>
                      }
                      content={() => componentRef2.current}
                  />

                  <Button className=' mb-2 add-new-btn rounded me-1 text-white' variant='warning'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16.14'
                      height='16.14'
                      viewBox='0 0 16.14 16.14'
                    >
                      <g
                        id='Icon_feather-download'
                        data-name='Icon feather-download'
                        transform='translate(-4 -4)'
                      >
                        <path
                          id='Path_59'
                          data-name='Path 59'
                          d='M19.64,22.5v2.9a1.58,1.58,0,0,1-1.682,1.451H6.182A1.58,1.58,0,0,1,4.5,25.4V22.5'
                          transform='translate(0 -7.214)'
                          fill='none'
                          stroke='#fff'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='1'
                        />
                        <path
                          id='Path_60'
                          data-name='Path 60'
                          d='M10.5,15l4.667,3.628L19.834,15'
                          transform='translate(-3.097 -4.301)'
                          fill='none'
                          stroke='#fff'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='1'
                        />
                        <path
                          id='Path_61'
                          data-name='Path 61'
                          d='M18,13.208V4.5'
                          transform='translate(-5.93)'
                          fill='none'
                          stroke='#fff'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='1'
                        />
                      </g>
                    </svg>
                    &nbsp;Download
                  </Button>{' '}

                </Col>
                <Col sm={6}>
                  <Row className={'bg-dark mx-1'}>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Earnings</h6>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <h6 className='fw-bold'></h6>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Basic Pay</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.net_pay_without_two_hours).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Leave</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_leave).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Special Holiday</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_special_day_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Regular/Legal Holiday</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_regular_holiday_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Overtime</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_overtime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Night Diff Amount</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.night_diff_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Sat. 2 Hours Amount</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.saturday_two_hours_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Five Days Incentive</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.five_days_incentive_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Adjustments</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.adjustment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  {
                    payslipData?.salary_details_to_allowance.map((allowance, index) => (
                        allowances.map(allow => (
                            allowance.allowance_id == allow.allowance_id ?
                                <Row className={' p-0 mx-1'}>
                                  <Col sm={6}>
                                    <small>{allow.allowance_name}</small>
                                  </Col>
                                  <Col sm={6} className='d-grid justify-content-end'>
                                    <small>{Number(allowance.amount_of_allowance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                                  </Col>
                                </Row> : ""
                        ))

                    ))
                  }

                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Total Earnings</h6>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <h6 className='fw-bold'>{Number(payslipData?.total_earnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                    </Col>
                  </Row>
                  <hr />
                  {/* DEDUCTIONS*/}
                  <Row className={'bg-dark mx-1'}>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Deductions</h6>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <h6 className='fw-bold'></h6>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Absences/Suspensions</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_absence_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Late</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_late_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Undertime</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_undertime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  {
                    payslipData?.salary_details_to_deduction.map((deduction, index) => (
                        deductions.map(deduct => (
                            deduction.deduction_id == deduct.deduction_id ?
                                <Row key={index} className={' p-0 mx-1'}>
                                  <Col sm={6}>
                                    <small>{deduct.deduction_name}</small>
                                  </Col>
                                  <Col sm={6} className='d-grid justify-content-end'>
                                    <small>{Number(deduction.amount_of_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                                  </Col>
                                </Row> : ""
                        ))

                    ))
                  }


                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Taxable Salary</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>0.00</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Total Deductions</h6>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <h6 className='fw-bold'>{Number(payslipData?.total_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                    </Col>
                  </Row>
                  <hr />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          {/* <Modal.Footer>*/}

          {/*    <Button variant="outline-primary" size={"sm"} onClick={handleClose}>*/}
          {/*        Cancel*/}
          {/*    </Button>*/}
          {/*    <Button variant="primary text-white" className="mx-2" size={"sm"} onClick={handleClose}>Save*/}
          {/*    </Button>*/}
          {/* </Modal.Footer>*/}
        </Modal>
        {/* ADD DEDUCTION MODAL*/}
        <Modal className='modal' show={showDeduction} centered onHide={handleCloseDeduction}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Non-Recurring Deductions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Form.Group as={Col} md="5" className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label className='text-muted required'>Deduction Name</Form.Label>
                  <Form.Select
                      size='sm'
                      name="employee_id"
                      value={recurringDeductionDetails.deduction_id}
                      onChange={handleDeductionNameChange}
                      autoFocus
                      // onBlur={handleBlur}
                      // isInvalid={touched.employee_id && !!errors.employee_id}
                  >
                    <option value="0">Select Deduction</option>
                    {nonRecurringDeductions.map((deduction, index) => (
                        <option value={deduction.id} key={index}>{deduction.deduction_name}</option>
                    ))}
                  </Form.Select>

                </Form.Group>
                <Form.Group as={Col} md="5" className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label className='text-muted required'>Amount</Form.Label>
                  <Form.Control
                      className="text-right"
                      size={'sm'}
                      type='text'
                      placeholder='0'
                      name="deduction_amount"
                      value={recurringDeductionDetails.amount}
                      onChange={handleAmountChange}
                      // onBlur={handleBlur}
                      // isInvalid={(touched.five_days_incentive && !!errors.five_days_incentive) || !!validation}
                      autoComplete='off'
                  />

                </Form.Group>
                <Form.Group as={Col} md="2" className='mb-3'>
                  <Form.Label className='text-muted' style={{color:'white'}}>&nbsp;&nbsp;</Form.Label>
                  <Button
                      onClick={handleAddDeduction}
                      className='p-0 pt-4 pb-0'
                      style={{ fontSize: '25px', color: 'white' }}
                      variant=' btn-transparent'
                      size='sm'
                      title={'Add Deduction'}
                  >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16.143'
                        height='16.143'
                        viewBox='0 0 16.143 16.143'
                    >
                      <g id='New' transform='translate(0.5 0.5)'>
                        <g
                            id='Icon_feather-plus-circle'
                            data-name='Icon feather-plus-circle'
                            transform='translate(-3 -3)'
                        >
                          <path
                              id='Path_46'
                              data-name='Path 46'
                              d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'
                              fill='none'
                              stroke='#a7a9ac'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                          />
                          <path
                              id='Path_47'
                              data-name='Path 47'
                              d='M18,12v6.057'
                              transform='translate(-7.429 -4.457)'
                              fill='none'
                              stroke='#f26930'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                          />
                          <path
                              id='Path_48'
                              data-name='Path 48'
                              d='M12,18h6.057'
                              transform='translate(-4.457 -7.429)'
                              fill='none'
                              stroke='#f26930'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                          />
                        </g>
                      </g>
                    </svg>
                  </Button>
                </Form.Group>
              </Row>
              <br/>
              <Row>
                <Table responsive>
                  <thead>
                  <tr>
                    <th style={{width:'40%'}}>Name</th>
                    <th style={{width:'40%'}}>Amount</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>

                  {
                    employeeNonRecurringDeductions.length > 0?
                        employeeNonRecurringDeductions.map((deduction,index) => (

                                <tr key={index}>
                                  <td>{deduction.deduction_name}</td>
                                  <td>{deduction.amount}</td>
                                  <td>
                                    <Button
                                        onClick={() => handleDeleteDeduction(deduction.deduction_name)}
                                        className=' p-1 pt-0 pb-0'
                                        style={{ fontSize: '12px', color: 'white' }}
                                        variant=' btn-transparent'
                                        size='sm'
                                    >
                                      <Delete/>
                                    </Button>
                                  </td>
                                </tr>
                        ))
                        : <tr><td colSpan={3}>No Non-recurring Deduction yet.</td></tr>
                  }

                  </tbody>
                </Table>
              </Row>
              <br/>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseDeduction}>
              Cancel
            </Button>
            <Button
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              onClick={handlePostDeduction}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ADD ALLOWANCE MODAL*/}
        <Modal className='modal' show={showAllowance} centered onHide={handleCloseAllowance}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Non-Recurring Allowances</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Form.Group as={Col} md="5" className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label className='text-muted required'>Allowance Name</Form.Label>
                  <Form.Select
                      size='sm'
                      name="employee_id"
                      value={recurringAllowanceDetails.allowance_id}
                      onChange={handleChangeAllowance}
                      autoFocus
                      // onBlur={handleBlur}
                      // isInvalid={touched.employee_id && !!errors.employee_id}
                  >
                    <option value="0">Select Allowance</option>
                    {nonRecurringAllowances.map((allowance, index) => (
                        <option value={allowance.id} key={index}>{allowance.allowance_name}</option>
                    ))}
                  </Form.Select>

                </Form.Group>
                <Form.Group as={Col} md="5" className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label className='text-muted required'>Amount</Form.Label>
                  <Form.Control
                      className="text-right"
                      size={'sm'}
                      type='text'
                      placeholder='0'
                      name="deduction_amount"
                      value={recurringAllowanceDetails.amount}
                      onChange={handleAmountChangeOnAllowance}
                      // onBlur={handleBlur}
                      // isInvalid={(touched.five_days_incentive && !!errors.five_days_incentive) || !!validation}
                      autoComplete='off'

                  />

                </Form.Group>
                <Form.Group as={Col} md="2" className='mb-3'>
                  <Form.Label className='text-muted' style={{color:'white'}}>&nbsp;&nbsp;</Form.Label>
                  <Button
                      onClick={handleAddAllowance}
                      className='p-0 pt-4 pb-0'
                      style={{ fontSize: '25px', color: 'white' }}
                      variant=' btn-transparent'
                      size='sm'
                      title={'Add Deduction'}
                  >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16.143'
                        height='16.143'
                        viewBox='0 0 16.143 16.143'
                    >
                      <g id='New' transform='translate(0.5 0.5)'>
                        <g
                            id='Icon_feather-plus-circle'
                            data-name='Icon feather-plus-circle'
                            transform='translate(-3 -3)'
                        >
                          <path
                              id='Path_46'
                              data-name='Path 46'
                              d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'
                              fill='none'
                              stroke='#a7a9ac'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                          />
                          <path
                              id='Path_47'
                              data-name='Path 47'
                              d='M18,12v6.057'
                              transform='translate(-7.429 -4.457)'
                              fill='none'
                              stroke='#f26930'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                          />
                          <path
                              id='Path_48'
                              data-name='Path 48'
                              d='M12,18h6.057'
                              transform='translate(-4.457 -7.429)'
                              fill='none'
                              stroke='#f26930'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                          />
                        </g>
                      </g>
                    </svg>
                  </Button>
                </Form.Group>
              </Row>
              <br/>
              <Row>
                <Table responsive>
                  <thead>
                  <tr>
                    <th style={{width:'40%'}}>Name</th>
                    <th style={{width:'40%'}}>Amount</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>

                  {
                    employeeNonRecurringAllowances.length > 0?
                        employeeNonRecurringAllowances.map((allowance,index) => (

                            <tr key={index}>
                              <td>{allowance.allowance_name}</td>
                              <td>{allowance.amount}</td>
                              <td>
                                <Button
                                    onClick={() => handleDeleteAllowance(allowance.allowance_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Delete/>
                                </Button>
                              </td>
                            </tr>
                        ))
                        : <tr><td colSpan={3}>No Non-recurring Deduction yet.</td></tr>
                  }

                  </tbody>
                </Table>
              </Row>
              <br/>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseAllowance}>
              Cancel
            </Button>
            <Button
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
                onClick={handlePostAllowance}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ATTENDANCE MODAL*/}
        <Modal className='modal' size="xl" centered show={showAttendance} onHide={handleCloseAttendance}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold ms-auto'>Employee Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={4} className="employee-name-label"><b>Employee Name:</b> &nbsp; &nbsp; {employeeName}</Col>
              <Col sm={5} className="employee-name-label"><b>Attendance Range:</b>  &nbsp; &nbsp; {payrollTitle}</Col>
              <Col sm={3} className='d-flex justify-content-end hidden'>
                <Button
                    className='add-new-btn rounded text-white'
                    variant='primary'
                    type="button"
                    onClick={handleShowAllImage}
                >
                  View Images
                </Button>
                &nbsp; &nbsp;
                <ReactToPrint
                    trigger={() =>
                        <Button
                            className='add-new-btn rounded text-white'
                            variant='primary'
                            type="button"
                            onClick={handlePrint}
                        >
                          &nbsp; Print &nbsp;
                        </Button>
                    }
                    content={() => componentRef.current}
                />

              </Col>
            </Row>
            <br/>
            <br/>
            <Row>
              <Table responsive>
                <thead>
                <tr>
                  <th className="text-center" style={{width: '9%'}}>Date</th>
                  <th className="text-center">Day</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Clock In AM</th>
                  <th className="text-center">Clock Out AM</th>
                  <th className="text-center">Late</th>
                  <th className="text-center">Worktime</th>
                  <th className="text-center">Overtime In</th>
                  <th className="text-center">Overtime Out</th>
                  <th className="text-center">Total Overtime</th>
                  <th className="text-center">Branch</th>
                </tr>
                </thead>
                <tbody>
                  {
                    attendance.map((att, index) => (
                        <tr key={index}>
                          <td className="table-row-middle-align">{att.date}</td>
                          <td className="table-row-middle-align">{att.day}</td>
                          <td className='text-center table-row-middle-align'>
                            <div className='d-grid'>
                              {(() => {
                                if (att.status == 'Leave') {
                                  return (
                                      <Button
                                          className=" p-1 pt-0 pb-0"
                                          style={{fontSize: '12px', color: 'white'}}
                                          variant="info"
                                          size="sm"
                                      >
                                        Leave
                                      </Button>
                                  )
                                } else if(att.status == 'Present') {
                                  return (
                                      <Button
                                          className=" p-1 pt-0 pb-0"
                                          style={{fontSize: '12px', color: 'white'}}
                                          variant="success"
                                          size="sm"
                                      >
                                        Present
                                      </Button>
                                  )
                                } else if(att.status == 'Rest') {
                                  return (
                                      <Button
                                          className=" p-1 pt-0 pb-0"
                                          style={{fontSize: '12px', color: 'white'}}
                                          variant="secondary"
                                          size="sm"
                                      >
                                        Rest Day
                                      </Button>
                                  )
                                }

                                return (
                                    <Button
                                        className=" p-1 pt-0 pb-0"
                                        style={{fontSize: '12px', color: 'white'}}
                                        variant='danger'
                                        size="sm"
                                    >
                                      Absent
                                    </Button>
                                )
                              })()}
                            </div>
                          </td>
                          <td className="text-center table-row-middle-align">{att.in_time != null ? att.in_time : '-'}</td>
                          <td className="text-center table-row-middle-align">{att.out_time != null ? att.out_time : '-'} {att.in_and_out_time_difference && att.in_and_out_time_difference != 0 ? <sup className="superscript-plus-one">+{att.in_and_out_time_difference}</sup> : ''}</td>
                          <td className="text-center table-row-middle-align">{att.total_late != null ? att.total_late : '-'}</td>
                          <td className="text-center table-row-middle-align">{att.total_worked_time != null ? att.total_worked_time : '-'}</td>
                          <td className="text-center table-row-middle-align">
                            {
                              att?.attendance_overtime?.length > 0 ?
                                  att?.attendance_overtime.map((overtime, index) => (
                                      <>{index > 0 ? <br/>: ""} {overtime.overtime_in}</>
                                  )) :
                                  '-'
                            }
                          </td>
                          <td className="text-center table-row-middle-align">
                            {
                              att?.attendance_overtime?.length > 0 ?
                                  att?.attendance_overtime.map((overtime, index) => (
                                      <>{index > 0 ? <br/>: ""} {overtime.overtime_out}</>
                                  )) :
                                  '-'
                            }
                          </td>
                          <td className="text-center table-row-middle-align">{att.total_attendance_overtime != null ? att.total_attendance_overtime : '-'}</td>
                          <td className="text-center table-row-middle-align">
                            {(() => {
                              if(att.branch != null){
                                return (
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        container={document.body}
                                        overlay={
                                          <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '230px', zIndex: '2000'}}>
                                            <Popover.Body>
                                              {
                                                <>

                                                  <Row>
                                                    <Col><b>Shift From:</b></Col>
                                                    <Col>{att.start_from ? moment(new Date("2023-01-01 " + att.start_from ?? "")).format("h:mm A") : "-"}</Col>
                                                  </Row>
                                                  <Row>
                                                    <Col><b>Shift To:</b></Col>
                                                    <Col>{att.end_to ? moment(new Date("2023-01-01 " + att.end_to ?? "")).format("h:mm A") : "-"}</Col>
                                                  </Row>
                                                </>
                                              }
                                            </Popover.Body>
                                          </Popover>
                                        }
                                    ><Button
                                        className='add-new-btn rounded p-0'
                                        variant='transparent'
                                    >{att.branch ?? "-"}</Button></OverlayTrigger>
                                )
                              }
                              return ("-")
                            })()}
                          </td>
                        </tr>
                    ))
                  }

                </tbody>
              </Table>
              <Pagination
                  itemsCount={itemsCount1}
                  itemsPerPage={itemsPerPage1}
                  currentPage={currentPage1}
                  lastPage={lastPage1}
                  setCurrentPage={setCurrentPage1}
                  alwaysShown={false}
                  pageClicked={(page: number) => {
                    getAttendanceByUser(
                        page,
                        attendanceDetails.employee_id,
                        attendanceDetails.payroll_id,
                        10
                    );
                  }
                  }
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={handleCloseAttendance}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>

        {/* All IMAGE MODAL*/}
        <Modal className='modal' size="xl" centered show={showAllImage} onHide={handleCloseAllImage}>
          <Modal.Header closeButton >
            <Modal.Title className='fw-bold ms-auto'>CAPTURED IMAGE/S</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={3} className="fw-bold employee-name-label">Employee Name: </Col>
              <Col sm={6} className="employee-name-label">{employeeName}</Col>
            </Row>
            <br/>
            <Table responsive size='sm'>
              <thead>
              <tr>
                <th className='text-center' style={{width: '20%'}}>Date</th>
                <th className='text-center'>Images</th>
              </tr>
              </thead>
              <tbody>
              {
                attendanceImageLocation.map((attendance, index) => (
                    <tr key={index}>
                      <td className="text-center" style={{verticalAlign: 'middle'}}>{attendance.date}</td>
                      <td>
                        <Row >
                          {
                            attendance.pictures.length > 0 ?
                                attendance.pictures.map((picture, index2) => (
                                    <Form.Group as={Col} key={index2} md="3" style={{padding:"10px"}}>
                                      <a href={IMAGE_BASE_URL + picture.photo_url} target="_blank" className="photo-a" rel="noreferrer">
                                        <div className="card" >
                                          <img style={{height: "130px", width: "auto", objectFit: "cover"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + picture.photo_url}/>
                                          <div className="card-body d-flex flex-column align-items-center" style={{padding: '5px'}}>
                                            {
                                              picture.attendance_status == "am_in_time"? <p className="card-text" style={{color:"#1EA66D", fontSize: '90%'}}>{attendance.in_time} - Clock in AM</p> :
                                                  picture.attendance_status == "am_out_time"? <p className="card-text" style={{color:"#DA2B5D", fontSize: '90%'}}>{attendance.out_time} - Clock out AM</p> :
                                                      picture.attendance_status == "pm_in_time"? <p className="card-text" style={{color:"#1EA66D", fontSize: '90%'}}>{attendance.in_time_pm} - Clock in PM</p> :
                                                          picture.attendance_status == "pm_out_time"? <p className="card-text" style={{color:"#DA2B5D", fontSize: '90%'}}>{attendance.out_time_pm} - Clock out PM</p> : ""
                                            }
                                            <p title={picture.address} className="card-text display-address" style={{color:"black", fontSize: '90%', marginTop: '-15px'}}>{picture.address}</p>
                                          </div>
                                        </div>
                                      </a>
                                    </Form.Group>


                                )) : <span className="text-center">No captured images.</span>
                          }
                        </Row>
                      </td>
                    </tr>
                ))
              }

              </tbody>
            </Table>

          </Modal.Body>
          <Modal.Footer>
            <Button
                onClick={handleCloseAllImage}
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/*EDIT MODAL DEDUCTION*/}
        <Modal className='modal modal-lg' centered show={showDetails} onHide={handleCloseDetails}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>WithHolding Tax</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Table responsive className='bordered-tabs-top mt-3'>
                <thead>
                <tr>
                  <th className={'text-center'} style={{width: '20%'}}>Name</th>
                  <th className={'text-center'}>De Minimis</th>
                  <th className={'text-center'}>Taxable Compensation</th>
                  <th className={'text-center'}>Compensation Level</th>
                  <th className={'text-center'}>Excess of Taxable Income</th>
                  <th className={'text-center'}>Tax on Excess Taxable Income</th>
                  <th className={'text-center'}>Base Tax</th>
                  <th className={'text-center'}>Withholding Tax</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={"text-left"}>{taxDetails.name}</td>
                    <td className={"text-right"}>{taxDetails.de_minimis}</td>
                    <td className={"text-right"}>{taxDetails.taxable_compensation}</td>
                    <td className={"text-right"}>{taxDetails.compensation_level}</td>
                    <td className={"text-right"}>{taxDetails.taxable_income_excess}</td>
                    <td className={"text-right"}>{taxDetails.taxable_income_tax}</td>
                    <td className={"text-right"}>{taxDetails.base_tax}</td>
                    <td className={"text-right"}>{taxDetails.withholding_tax}</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
                type="submit"
                // disabled={isSubmitting}
                form="branchCreate"
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
                onClick={handleCloseDetails}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* COMPUTATION GUIDE MODAL*/}
        <Modal className='modal' size="lg" centered show={showComputationGuide} onHide={handleCloseComputationGuide}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold ms-auto'>Guide Computation for Holiday Pay, Premium Pay, Overtime Pay, and Night Shift Differential</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <br/>
            <Row>
              <Table responsive>
                <thead>
                <tr>
                  <th style={{width: '8%'}} className="text-center">No.</th>
                  <th style={{width: '52%'}} className="text-center">Work On</th>
                  <th style={{width: '40%'}} className="text-center">Equivalent Pay</th>
                </tr>
                </thead>
                <tbody>
                {
                  computationGuide.map((com, index) => (
                      <tr key={index} style={{lineHeight: '80%'}}>
                        <td className={'text-center'}>{com.no}</td>
                        <td>{com.name}</td>
                        <td>{com.rate} or <b>{com.percentage}%</b></td>
                      </tr>
                  ))
                }
                </tbody>
              </Table>
              <div style={{marginLeft: '10px'}}>
                <Pagination
                    itemsCount={40}
                    itemsPerPage={15}
                    currentPage={computationGuideCurrentPage}
                    lastPage={3}
                    setCurrentPage={setComputationCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      handleChangeComputationGuidePage(page)
                      // getAttendanceByUser(
                      //     page,
                      //     attendanceDetails.employee_id,
                      //     attendanceDetails.payroll_id,
                      //     10
                      // );
                    }
                    }
                />
              </div>

            </Row>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>

        <PrintAttendancePayrollComponent
            ref={componentRef}
            attendance={attendance}
            employeeName={employeeName}
            attendanceRange={payrollTitle}
        />

        <PrintPayslipComponent
            ref={componentRef2}
            payslipData={payslipData}
            allowances={allowances}
            deductions={deductions}
        />

        <Alert
            type="success"
            show={showSuccess}
            message={success}
            setShow={() => {
              setShowSuccess(false);
            }
            }
        />

        <Alert
            type="error"
            show={showError}
            message={error}
            setShow={() => {
              setShowError(false);
            }
            }
        />
        <br />
      </Container>
    </>
  )
}
