import React, {useContext, useState, useMemo, useEffect} from 'react'
import Image from 'react-bootstrap/Image'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import { Link, useLocation } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ExpandMore } from '@material-ui/icons'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import { Collapse } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles';
import {ClickAwayListener} from '@mui/base';
import { drawerLabels } from './constant'
import { MainContext } from '../../context/MainContext'
import './style.css'
import {
  StyledEmployeeName,
  StyledPosition,
  StyledDrawer,
  DrawerHeader,
  StyledEmployeeContainer,
  StyledTypoListItemText,
  StyledTypoSubNav, DrawerClose,
} from './StyledComponents'
import axios from "../../api";
import {IAPI_Response} from "../../api/types";
import {API_ENDPOINT} from "../../utils/globalConstant";
import {IMenu, IModule} from "./type";
import HomeIcon from '../../asset/SvgComponent/HomeIcon'
import HomeIconActive from '../../asset/SvgComponent/HomeActiveIcon'
import AdminIcon from '../../asset/SvgComponent/AdminIcon'
import AdminActiveIcon from '../../asset/SvgComponent/AdminActiveIcon'
import EmployeeIcon from '../../asset/SvgComponent/EmployeeIcon'
import EmployeeActiveIcon from '../../asset/SvgComponent/EmployeeActiveIcon'
import LeavesIcon from '../../asset/SvgComponent/LeavesIcon'
import LeavesActiveIcon from '../../asset/SvgComponent/LeavesActiveIcon'
import AttendanceIcon from '../../asset/SvgComponent/AttendanceIcon'
import AttendanceActiveIcon from '../../asset/SvgComponent/AttendanceActiveIcon'
import PayrollIcon from '../../asset/SvgComponent/PayrollIcon'
import PayrollActiveIcon from '../../asset/SvgComponent/PayrollActiveIcon'
import MonitoringIcon from "asset/SvgComponent/MonitoringIcon";


const Drawer = () => {
  const [openDropdown, setopenDropdown] = useState(0)
  const [openDropdownSubMenu, setopenDropdownSubMenu] = useState(0)
  const [openDropdownSetup, setopenDropdownSetup] = useState(0)
  const [activeNav, setActiveNav] = useState('Home')
  const [activeSubLink, setActiveSubLink] = useState('')
  const mainCont = useContext(MainContext)
  const open = useMemo(() => mainCont?.openDrawer, [mainCont?.openDrawer])
  const [employeeDetails, setEmployeeDetails] = useState({
    name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
    designation: localStorage.getItem('designation'),
    role: localStorage.getItem('role_id')
  })
  const [modules, setModule] = useState<IModule[]>([
    {
      id: 0,
      name: '',
      icon_class: '',
      icon_class_active: '',
      sidenav_show: false,
      sidenav_sublinks: []
    }
  ])
  const [currentSubMenu, setCurrentSubMenu] = useState('')
  const [pathNameDetails, setPathNameDetails] = useState({pathName: "", module_id: ""})


  const handleClick = (itemID: any) => {
    if (itemID == openDropdown) {
      setopenDropdown(0)
    }else{
      setopenDropdown(itemID)
    }

  }

  const handleClickSubLink = (itemID: any) => {
    if (itemID == openDropdownSetup) {
      setopenDropdownSetup(0)
    }else{
      setopenDropdownSetup(itemID)
    }

  }

  const handleClickSubMenu = (itemID: any) => {
    if (itemID == openDropdownSubMenu) {
      setopenDropdownSubMenu(0)

    }else{
      setopenDropdownSubMenu(itemID)
    }


  }

  const handleDrawerChange = () => {
    // @ts-ignore
    mainCont?.setOpenDrawer((data: boolean) => !data)
  }

  const handleClickAway = () => {

    if(!open){
      // @ts-ignore
      mainCont?.setOpenDrawer((data: boolean) => true)
    }
    // else{
    //   // @ts-ignore
    //   mainCont?.setOpenDrawer((data: boolean) => false)
    // }

  }

  const p = window.location.pathname
  let pathname = '/'
  pathname = p


  //get all modules
  const getAllModules = async () => {

    try {
      const res = await axios.get<IAPI_Response<IModule[]>>(API_ENDPOINT.GET_MODULES_BY_ROLE+"/"+employeeDetails.role)
      const result = await res
      if (result?.data?.results?.length) {
        const modules = result?.data?.results;
        modules.map((module, index) => {
          if(module.name == "Administration"){
            module.icon_class = <HomeIcon />
            module.icon_class_active =<HomeIconActive />
          }else if(module.name == "Employees"){
            module.icon_class = <EmployeeIcon />
            module.icon_class_active =<EmployeeActiveIcon />
          }else if(module.name == "Leaves"){
            module.icon_class = <LeavesIcon />
            module.icon_class_active =<LeavesActiveIcon />
          }else if(module.name == "Attendance"){
            module.icon_class = <AttendanceIcon />
            module.icon_class_active =<AttendanceActiveIcon />
          }else if(module.name == "Payroll"){
            module.icon_class = <PayrollIcon />
            module.icon_class_active =<PayrollActiveIcon />
          }else if(module.name == "Setup"){
            module.icon_class = <PayrollIcon />
            module.icon_class_active =<PayrollActiveIcon />
          }else if(module.name == "Monitoring"){
            module.icon_class = <MonitoringIcon />
            module.icon_class_active = <MonitoringIcon />
          }

          if(module.sidenav_sublinks.length > 0){
            module.sidenav_sublinks.map((sublinks : any) => {
              if(sublinks.submenu_sublinks.length > 0){
                sublinks.submenu_sublinks.map((submenu_link: any) => {
                  if(submenu_link.menu_url == window.location.pathname){
                    setPathNameDetails({pathName: window.location.pathname, module_id: submenu_link.module_id})
                  }
                })
              }else{
                if(sublinks.menu_url == window.location.pathname){
                  setPathNameDetails({pathName: window.location.pathname, module_id: sublinks.module_id})
                }
              }

            })
          }

        })
        setModule(modules)
        localStorage.setItem('modules', JSON.stringify(modules));
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const drawer = <StyledDrawer className={open ? 'drawer-open' : 'drawer-close'} variant='permanent' open={open}>
      {open && (

          <DrawerHeader>
            <h1 className='text-center'>
              <div>
              </div>
              <Link to='/'>
                <Image className='text-center' style={{ width: '56%' }} src='/Octaneware1.png' />
              </Link>
            </h1>
          </DrawerHeader>


      )}
      {open && (
          <StyledEmployeeContainer>
            <StyledEmployeeName>{employeeDetails.name}</StyledEmployeeName>
            <StyledPosition>{employeeDetails.designation}</StyledPosition>
          </StyledEmployeeContainer>
      )}

      <List>
        <DrawerClose>
          <button
              type="button"
              className="btn-close d-flex justify-content-end"
              aria-label="Close"
              // style={{fontSize: '50%'}}
              onClick={ () => {
                handleDrawerChange()
              }}
          >
          </button>
        </DrawerClose>
        <ListItem disablePadding sx={{ justifyContent: 'center' }}>
          <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
                fontSize: '.75rem',
                display: open ? 'none' : 'visible',
              }}
          >

            <Link to='/'>
              <Avatar className='bg-transparent' sx={{ width: 45, height: 50 }}>
                <Image
                    className='text-center'
                    style={{
                      width: '100%',
                    }}
                    src='/Octaneware.png'
                />
              </Avatar>
            </Link>
          </ListItemIcon>
        </ListItem>
        <Link to='/home'>
          <ListItem key={0} disablePadding sx={{ display: 'block' }}>
            <OverlayTrigger
                placement={'right'}
                overlay={
                  <Tooltip
                      // SHOW ONLY IF MINIMIZED SIDEBAR
                      style={{
                        zIndex: '2000',
                        display: open ? 'none' : 'visible',
                      }}
                  >
                    Home
                  </Tooltip>
                }
            >
              <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  key={module.id}
                  onClick={() => {
                    handleClick(0)
                    setActiveNav('Home')
                    setActiveSubLink('/home')
                  }}
              >
                <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      fontSize: '.75rem',
                    }}
                >
                  {/*{pathname !== module.sidenav_link ? item.sidenav_icon : item.sidenav_icon_active}*/}
                  {openDropdown !== 0 || pathname != "/home" ? <AdminIcon /> : <AdminActiveIcon /> }
                </ListItemIcon>

                <ListItemText
                    primary={
                      <StyledTypoListItemText
                          active={activeNav === 'Home'}
                          variant='body2'
                      >
                        Home
                      </StyledTypoListItemText>
                    }
                    // sx={{
                    //   opacity: open ? 1 : 0,
                    //   fontWeight: openDropdown === 0 ? 700 : 100,
                    // }}
                    sx={(theme) => {
                      return ({
                        opacity: open ? 1 : 0,
                        fontWeight: openDropdown === 0 ? 700 : 100,
                        [theme.breakpoints.down('sm')]: {
                          opacity: 1,
                          ml: 1,
                        },

                      })}}
                />

              </ListItemButton>
            </OverlayTrigger>
          </ListItem>
        </Link>

        {modules.map((module) => (
            (module.sidenav_show) ? (
                <ListItem key={module.id} disablePadding sx={{ display: 'block' }}>
                  <OverlayTrigger
                      placement={'right'}
                      overlay={
                        <Tooltip
                            // SHOW ONLY IF MINIMIZED SIDEBAR
                            style={{
                              zIndex: '2000',
                              display: open ? 'none' : 'visible',
                            }}
                        >
                          {module.name}
                        </Tooltip>
                      }
                  >
                    <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                        key={module.id}
                        onClick={() => {
                          handleClick(module.id)
                          setActiveNav(module.name)
                          // setActiveSubLink(module.sidenav_sublinks[0]?.name)
                        }}
                    >
                      <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            fontSize: '.75rem',
                          }}
                      >
                        {/*{pathname !== module.sidenav_link ? item.sidenav_icon : item.sidenav_icon_active}*/}
                        {openDropdown !== module.id ? module.icon_class : module.icon_class_active}
                      </ListItemIcon>

                      <ListItemText
                          primary={
                            <StyledTypoListItemText
                                active={activeNav === module.name}
                                variant='body2'
                            >
                              {module.name}
                            </StyledTypoListItemText>
                          }
                          // sx={{
                          //   opacity: open ? 1 : 0,
                          //   fontWeight: openDropdown === module.id ? 700 : 100,
                          // }}
                          sx={(theme) => {
                            return ({
                              opacity: open ? 1 : 0,
                              fontWeight: openDropdown === module.id ? 700 : 100,
                              [theme.breakpoints.down('sm')]: {
                                opacity: 1,
                                ml: 1,
                              },

                            })}}
                      />

                      <>
                        {openDropdown === module.id ? (
                            <ExpandMore
                                htmlColor='#a7a9ac'
                                style={{ display: open ? 'block' : 'none' }}
                            />
                        ) : (
                            <KeyboardArrowRightOutlinedIcon
                                htmlColor='#a7a9ac'
                                style={{ display: open ? 'block' : 'none' }}
                            />
                        )}
                      </>
                    </ListItemButton>
                  </OverlayTrigger>

                  {/* SUBLINKS*/}
                  <Collapse in={openDropdown === module.id} timeout='auto' unmountOnExit={true}>
                    {module.sidenav_sublinks.map((sublink: any) => (
                        sublink.parent_id == 0 ?
                            sublink.menu_url !== "" ?
                                sublink.hasPermission ?
                                    <Link key={sublink.id} to={sublink.menu_url} className='links'>
                                      <List component='div' disablePadding>
                                        <OverlayTrigger
                                            placement='right'
                                            overlay={
                                              <Tooltip
                                                  style={{
                                                    zIndex: '2000',
                                                    display: open ? 'none' : 'visible',
                                                  }}
                                              >
                                                {sublink.name}
                                              </Tooltip>
                                            }
                                        >
                                          <ListItemButton
                                              key={sublink.id}
                                              sx={(theme) => {
                                                return ({
                                                  pl: open ? 8 : 2,
                                                  [theme.breakpoints.down('sm')]: {
                                                    pl: open ? 8 : 7,
                                                  }
                                                })}}
                                              onClick={() => {
                                                handleClickSubMenu(sublink.id)
                                                setActiveSubLink(sublink.name)
                                              }}
                                          >
                                            <ListItemText
                                                primary={
                                                  <StyledTypoSubNav
                                                      active={activeSubLink === sublink.name || pathname == sublink.menu_url}
                                                      variant='body2'
                                                  >
                                                    {sublink.name}
                                                  </StyledTypoSubNav>
                                                }
                                            />

                                          </ListItemButton>
                                        </OverlayTrigger>

                                      </List>
                                    </Link>: "" :
                                <List component='div' disablePadding>
                                  <OverlayTrigger
                                      placement='right'
                                      overlay={
                                        <Tooltip
                                            style={{
                                              zIndex: '2000',
                                              display: open ? 'none' : 'visible',
                                            }}
                                        >
                                          {sublink.name}
                                        </Tooltip>
                                      }
                                  >
                                    <ListItemButton
                                        key={sublink.id}
                                        sx={(theme) => {
                                          return ({
                                            pl: open ? 8 : 2,
                                            [theme.breakpoints.down('sm')]: {
                                              pl: open ? 8 : 7,
                                            }
                                          })}}
                                        onClick={() => {
                                          handleClickSubMenu(sublink.id)
                                          setActiveSubLink(sublink.name)
                                        }}
                                    >
                                      <ListItemText
                                          primary={
                                            <StyledTypoSubNav
                                                active={activeSubLink === sublink.name}
                                                variant='body2'
                                            >
                                              {sublink.name}
                                            </StyledTypoSubNav>
                                          }
                                      />
                                      {sublink.menu_url === '' ? (
                                          <>
                                            {openDropdownSubMenu === sublink.id ? (
                                                <ExpandMore
                                                    htmlColor='#a7a9ac'
                                                    style={{ display: open ? 'block' : 'none' }}
                                                />
                                            ) :  (
                                                <KeyboardArrowRightOutlinedIcon
                                                    htmlColor='#a7a9ac'
                                                    style={{ display: open ? 'block' : 'none' }}
                                                />
                                            )}
                                          </>
                                      ):""}
                                    </ListItemButton>
                                  </OverlayTrigger>
                                  <>
                                    <Collapse in={openDropdownSubMenu === sublink.id} timeout='auto'>
                                      {
                                        sublink.submenu_sublinks.map((subMenu: any) => (
                                            subMenu.hasPermission ?
                                                <Link key={subMenu.id} to={subMenu.menu_url} className='links '>
                                                  <List component='div' disablePadding>
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        overlay={
                                                          <Tooltip
                                                              style={{
                                                                zIndex: '2000',
                                                                display: open ? 'none' : 'visible',
                                                              }}
                                                          >
                                                            {subMenu.name}
                                                          </Tooltip>
                                                        }
                                                    >
                                                      <ListItemButton
                                                          key={subMenu.id}
                                                          sx={{ pl: open ? 5 : 4 }}
                                                          onClick={() => setActiveSubLink(subMenu.name)}
                                                      >
                                                        <ListItemIcon>
                                                          {/* <TitleOutlined*/}
                                                          {/*    fontSize="small"/>*/}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                              <StyledTypoSubNav
                                                                  active={activeSubLink === subMenu.name}
                                                                  variant='body2'
                                                              >
                                                                {subMenu.name}
                                                              </StyledTypoSubNav>
                                                            }
                                                        />
                                                      </ListItemButton>
                                                    </OverlayTrigger>
                                                  </List>
                                                </Link> : ""
                                        ))
                                      }

                                    </Collapse>
                                  </>

                                </List>
                            : ""
                    ))}
                  </Collapse>


                </ListItem>

            ) : null
        ))}
      </List>
    </StyledDrawer>


  useEffect(() => {
    ;(async () => {
      if(localStorage.getItem('jwt')){
        getAllModules();
      }

    })()
  }, [])

  return (
      <>
        {
            !open ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  {drawer}
                </ClickAwayListener>
            ) : drawer
        }
      </>

  )
}

export default Drawer
