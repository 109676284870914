import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Table
} from 'react-bootstrap'
// import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
    API_ENDPOINT,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import {IList, IItem, IComputationGuide} from './types'
import moment from "moment"

export default function Index() {
    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(10)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)

    const [validation, setValidation] = React.useState('')
    const [error, setError] = React.useState('')
    const [success, setSuccess] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showLoading, setShowLoading] = React.useState(false)

    const [showCreate, setShowCreate] = React.useState(false)
    const handleCloseCreate = () => setShowCreate(false)
    const handleShowCreate = () => setShowCreate(true)

    const [showEdit, setShowEdit] = React.useState(false)
    const handleCloseEdit = () => setShowEdit(false)
    const handleShowEdit = () => setShowEdit(true)

    const [showConfirm, setShowConfirm] = React.useState(false)
    const handleCloseConfirm = () => setShowConfirm(false)
    const handleShowConfirm = (id: any) => {
        setShowConfirm(true)
        setItem({
            id: id,
            name: '',
            rate: ''
        })
    }

    const [lists, setData] = useState<IList[]>([])
    const [item, setItem] = useState<IItem>({
        id: 0,
        name: '',
        rate: ''
    })
    const [dateFormat, setDateFormat] = useState('')

    const getComputationGuide = async (page_number: null | number) => {
        setShowLoading(true)
        try {
            const res = await axios.get<IAPI_Response<IComputationGuide>>(API_ENDPOINT.GET_COMPUTATION_GUIDE, {
                params: {
                    page: page_number,
                },
            })
            const result = await res
            if (result?.data?.results?.data?.length) {
                setData(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }
    }

    const showComputationGuide = async (id: null | number) => {
        try {
            const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_COMPUTATION_GUIDE +'/'+ id)
            const result = await response
            if (result?.data?.results) {
                setItem(result?.data?.results)
            }
            handleShowEdit()
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    // const createJobLevel = async (value: any, {resetForm} : any) => {
    //     await axios.post(API_ENDPOINT.POST_JOB_LEVEL, {
    //         job_level_name: value.job_level_name
    //     }).then(response => {
    //         if (response?.data?.status * 1 === 1) {
    //             setShowSuccess(true)
    //             setSuccess(SUCCESS_MESSAGE.JOB_LEVEL.CREATED)
    //             getJobLevel(null);
    //         }
    //         resetForm({})
    //         handleCloseCreate();
    //     }).catch(error => {
    //         setValidation(error.response.data.message)
    //     })
    //
    //     setTimeout(() => {
    //         setShowSuccess(false)
    //         setShowError(false)
    //         setValidation('')
    //     }, 3000);
    // }
    //
    const updateComputationGuide = async (value: any, {resetForm} : any) => {
        await axios.put(API_ENDPOINT.PUT_COMPUTATION_GUIDE +'/'+ value.id, {
            name: value.name,
            rate: value.rate
        }).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.COMPUTATION_GUIDE.UPDATED)
                getComputationGuide(null);
            }
            resetForm({})
            handleCloseEdit();
        }).catch(error => {
            setValidation(error.response.data.message)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
            setValidation('')
        }, 3000);
    }

    const deleteComputationGuide = async (id: null | number) => {
        try {
            const response = await axios.delete(API_ENDPOINT.DELETE_COMPUTATION_GUIDE +'/'+ id)
            const result = await response
            if (result?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.COMPUTATION_GUIDE.DELETED)
                getComputationGuide(null);
            }
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }
        handleCloseConfirm()
        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    useEffect(() => {
        ;(async () => {
            getComputationGuide(null)
            setDateFormat(localStorage.getItem('date_format') ?? "")
        })()
    }, [])

    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Computation Guide for Holiday Pay, Premium Pay, Overtime Pay and Night Shift Differential</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='/home'>
                        Setup
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        className=' text-decoration-none'
                    >
                        Leave
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Computation Guide</Breadcrumb.Item>
                </Breadcrumb>

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <Card className='border-0  p-0 bordered-card'>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                {/*<span className='fw-bold'>Job Level List</span>*/}
                            </Col>
                            <Col sm={6} className='d-flex justify-content-end hidden'>
                                <InputGroup size='sm' className={'w-25'}>
                                    <Form.Control
                                        aria-label='search'
                                        aria-describedby='inputGroup-sizing-sm'
                                        placeholder='Search...'
                                    />
                                    <InputGroup.Text className='bg-transparent search-input '>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup>
                                {/*<Button*/}
                                {/*    onClick={handleShowCreate}*/}
                                {/*    className=' add-new-btn rounded mx-2 text-white'*/}
                                {/*    variant='warning'*/}
                                {/*>*/}
                                {/*    <EditIcon/>*/}
                                {/*    &nbsp;Add Job Level*/}
                                {/*</Button>*/}
                            </Col>
                        </Row>

                        <Table responsive className='bordered-tabs-top mt-3'>
                            <thead>
                            <tr>
                                <th style={{ width: '10%' }}>No.</th>
                                <th style={{ width: '35%' }}>Name</th>
                                <th style={{ width: '20%' }}>Rate</th>
                                <th className={'text-center'}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <>
                                {lists.length > 0 ? (
                                    lists.map((item, index) => (
                                        <tr className='mb-2' key={index}>
                                            <td>{from + index}</td>
                                            <td className='fw-bold'>{item.name} </td>
                                            <td>{item.rate} or {(Number(item.rate) * 100).toFixed(0)}%</td>
                                            <td className='text-center'>
                                                <Button
                                                    onClick={() => showComputationGuide(item.id)}
                                                    className=' p-1 pt-0 pb-0'
                                                    style={{ fontSize: '12px', color: 'white' }}
                                                    variant=' btn-transparent'
                                                    size='sm'
                                                >
                                                    <Write/>
                                                </Button>
                                                <Button
                                                    onClick={() => handleShowConfirm(item.id)}
                                                    className=' p-1 pt-0 pb-0'
                                                    style={{ fontSize: '12px', color: 'white' }}
                                                    variant=' btn-transparent'
                                                    size='sm'
                                                >
                                                    <Delete/>
                                                </Button>
                                            </td>
                                            <br />
                                        </tr>
                                    ))
                                ) : (
                                    <tr className='no-item'>
                                        <td colSpan={4}>{TEXT.NOT_FOUND}</td>
                                    </tr>
                                )}
                            </>
                            </tbody>
                        </Table>

                        <Pagination
                            itemsCount={itemsCount}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            lastPage={lastPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={false}
                            pageClicked={(page: number) => {
                                getComputationGuide(page);
                            }
                            }
                        />

                        <Dialog
                            show={showConfirm}
                            setShow={handleCloseConfirm}
                            setEvent={() => {
                                deleteComputationGuide(item.id)
                            }
                            }
                        />

                        <Loader show={showLoading} />
                    </Card.Body>
                </Card>


                {/*/!* EDIT MODAL*!/*/}
                <Formik
                    initialValues={item}
                    validationSchema={validationSchema}
                    onSubmit={updateComputationGuide}
                    enableReinitialize
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (

                        <Form id="designationUpdate" noValidate onSubmit={handleSubmit}>
                            <Modal className='modal' show={showEdit} centered onHide={handleCloseEdit}>
                                <Modal.Header closeButton>
                                    <Modal.Title className='fw-bold'>Edit Computation Guide</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                                <Form.Label className='text-muted'>Name</Form.Label>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='text'
                                                    placeholder='Name'
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.name && !!errors.name || !!validation}
                                                    autoFocus />
                                                {
                                                    validation != '' ?
                                                        (Object.keys(validation).map((message : any, index) => (
                                                                <Form.Control.Feedback type="invalid" key={index}>
                                                                    {validation[message][index]}
                                                                </Form.Control.Feedback>
                                                            ))
                                                        ) : (
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.name}
                                                            </Form.Control.Feedback>
                                                        )
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                                <Form.Label className='text-muted'>Rate</Form.Label>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='text'
                                                    placeholder='Rate'
                                                    name="rate"
                                                    value={values.rate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.rate && !!errors.rate || !!validation}
                                                    autoFocus />
                                                {
                                                    validation != '' ?
                                                        (Object.keys(validation).map((message : any, index) => (
                                                                <Form.Control.Feedback type="invalid" key={index}>
                                                                    {validation[message][index]}
                                                                </Form.Control.Feedback>
                                                            ))
                                                        ) : (
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.rate}
                                                            </Form.Control.Feedback>
                                                        )
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        form="designationUpdate"
                                        variant='primary text-white'
                                        size={'sm'}
                                        className='mx-2'
                                    >
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                    )}
                </Formik>

            </Container>
        </>
    )
}
