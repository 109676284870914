import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button, ButtonToolbar,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row, Stack,
    Table, ToggleButton, ToggleButtonGroup
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
    API_ENDPOINT,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    TEXT, IMAGE_BASE_URL
} from 'utils/globalConstant'
import moment from "moment"
import {Avatar} from "@mui/material";
import View from "asset/SvgComponent/View";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {IChangeSchedule} from "../../Attendance/ShiftSwap/types";
import {IEmployees} from "./types";
import {
    IActivity,
    IActivityTime,
    IAttendanceSettings, IPieChartData,
    IScreenshot
} from "../../Attendance/AttendanceReport/Retail/types";
import PieChartWhiteIcon from "asset/SvgComponent/PieChartWhiteIcon";
import PieChartIcon from "asset/SvgComponent/PieChartIcon";
import TableListWhiteIcon from "asset/SvgComponent/TableLIstWhiteIcon";
import TableListIcon from "asset/SvgComponent/TableListIcon";
import ScreenshotIcon from "asset/SvgComponent/ScreenshotIcon";
import PieChart from "components/PieChart";

export default function Index() {
    const [key, setKey] = useState('team-view')

    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(10)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)

    const [validation, setValidation] = React.useState('')
    const [error, setError] = React.useState('')
    const [success, setSuccess] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showLoading, setShowLoading] = React.useState(false)

    const [dateFormat, setDateFormat] = useState('')

    const [employees, setEmployees] = React.useState<IEmployees[]>([])
    const [searchedKeyword, setSearchedKeyword] = React.useState("")

    const [selectedEmployeeAttendance, setSelectedEmployeeAttendance] = useState(0);
    const [activityTime, setActivityTime] = React.useState<IActivityTime[]>([])
    const [screenshots, setScreenshots] = React.useState<IScreenshot[]>([])
    const [applications, setApplications] = React.useState<string[]>([])
    const [percentage, setPercentage] = React.useState<number[]>([])
    const [sites, setSites] = React.useState<string[]>([])
    const [sitesPercentage, setSitesPercentage] = React.useState<number[]>([])
    const [view, setView] = React.useState("pie-chart")
    const [timeHighlight, setTimeHighlight] = React.useState("")
    const [activityLevel, setActivityLevel] = React.useState("")
    const [modalTitle, setModalTitle] = React.useState("Pie Chart")
    const [attendanceSettings, setAttendanceSettings] = useState<IAttendanceSettings>({
        takeScreenshot: 0,
        noOfScreenshotPerHour: 0,
        allowBlurScreenshot: 0,
        trackMouseKeyboardActivity: 0,
        trackAppUrl: 0,
        autoPause: 0,
        noOfMinutesAutopause: 0
    })

    const [showActivityTime, setShowActivityTime] = React.useState(false)
    const handleShowActivityTime = () => setShowActivityTime(true)
    const handleCloseActivityTime = () => {
        setView("pie-chart")
        setTimeHighlight("")
        setModalTitle("Pie Chart")
        setShowActivityTime(false)
    }

    const displayPie = (value: number) => {
        // Determine color based on value
        let color = '';
        if (value > 50) {
            color = '#82C341';
        } else if (value > 25) {
            color = '#ff7043';
        } else {
            color = '#DA2B5D';
        }

        return (
            <div className="circular-progress" title={value + "%"}>
                <div
                    className="circle"
                    style={{
                        background: `conic-gradient(${color} ${value * 3.6}deg, #f0f0f0 ${value * 3.6}deg)`
                    }}
                >
                </div>
            </div>
        );
    }

    const getAveragePercentage = () => {
        let averagePercentage = 0;
        activityTime.map(act => {
            averagePercentage += parseInt(act.activity_percentage)
        })

        return `${(averagePercentage/activityTime.length).toFixed(0)}%`
    }

    const handleDisplayActivityLevel = (activity_level: number) => {
        if(activity_level < 50) {
            return <span style={{color: '#DA2B5D',fontSize: '120%'}}>{activity_level} %</span>
        }else if(activity_level < 75){
            return <span style={{color: '#ff7043', fontSize: '120%'}}>{activity_level} %</span>
        }else{
            return <span style={{color: '#82C341', fontSize: '120%'}}>{activity_level} %</span>
        }

    }

    const handleShowApplication = async (e: any, start_time: string, end_time: string) => {

        try {
            const response = await axios.get<IAPI_Response<IPieChartData>>(API_ENDPOINT.GET_ACTIVITY_PER_TIME, {
                params: {
                    employee_attendance_id: selectedEmployeeAttendance,
                    from_time: start_time,
                    to_time: end_time
                },
            })
            const result = await response
            if (result?.data?.results?.applications?.length) {
                setApplications(result?.data?.results?.applications ?? ['Others'])
                setPercentage(result?.data?.results?.percentage ?? [0])
            }else{
                setApplications(['No Activity'])
                setPercentage([0])
            }

            if(result?.data?.results?.sites?.length){
                setSites(result?.data?.results?.sites ?? ['Others'])
                setSitesPercentage(result?.data?.results?.sites_percentage ?? [0])
            }else{
                setSites(['No Activity'])
                setSitesPercentage([0])
            }

            setTimeHighlight(start_time)

            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const handleSearchedKeyword = (e: any) => {
        setSearchedKeyword(e.target.value)
        getEmployeesPresent(e.target.value)
    }

    const getAllAttendanceSettings = async (id: number) => {
        try {
            const res = await axios.get<IAPI_Response<IAttendanceSettings>>(API_ENDPOINT.GET_ATTENDANCE_SETTINGS_BY_EMPLOYEE+"/"+id)
            const result = await res

            if(result.data.results){
                setAttendanceSettings(result.data.results)
            }

        } catch {
            console.log('There is something wrong')
        }
    }

    const handleClickCard = async (id: number) => {
        if(id != 0){
            try {
                const res = await axios.get<IAPI_Response<IActivity>>(API_ENDPOINT.GET_ACTIVITY_TIME, {
                    params: {
                        employee_attendance_id: id
                    },
                })
                const result = await res
                setSelectedEmployeeAttendance(id)
                setActivityTime(result?.data?.results?.activity_time ?? [])

                if(result?.data?.results?.pie_chart_data?.applications && result?.data?.results?.pie_chart_data?.applications.length > 0){
                    setApplications(result?.data?.results?.pie_chart_data?.applications ?? ['Others'])
                    setPercentage(result?.data?.results?.pie_chart_data?.percentage ?? [0])
                }else{
                    setApplications(['No Activity'])
                    setPercentage([0])
                }

                if(result?.data?.results?.pie_chart_data?.sites && result?.data?.results?.pie_chart_data?.sites.length > 0){
                    setSites(result?.data?.results?.pie_chart_data?.sites ?? ['Others'])
                    setSitesPercentage(result?.data?.results?.pie_chart_data?.sites_percentage ?? [0])
                }else{
                    setSites(['No Activity'])
                    setSitesPercentage([0])
                }

                if(result?.data?.results?.screenshots.length && result?.data?.results?.screenshots.length > 0){
                    setScreenshots(result?.data?.results?.screenshots)
                }else{
                    setScreenshots([])
                }

                handleShowActivityTime()

            } catch {
                console.log('There is something wrong')
            }
        }else{
            setActivityTime([])
            setApplications(['No Activity'])
            setPercentage([0])
            setSites(['No Activity'])
            setSitesPercentage([0])
            handleShowActivityTime()
        }
    }

    const getEmployeesPresent = async (searchedKeyword: string) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IEmployees[]>>(API_ENDPOINT.GET_EMPLOYEES_PRESENT, {
                params: {
                    searched: searchedKeyword
                }
            })

            const result = await response
            if (result?.data?.results?.length) {
                setEmployees(result?.data?.results)
            }else{
                setEmployees([])
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    useEffect(() => {
        setDateFormat(localStorage.getItem('date_format') ?? "")
        const fetchEmployees = () => {
            getEmployeesPresent(searchedKeyword)

        }
        fetchEmployees() // Call immediately on mount

        const interval = setInterval(fetchEmployees, 60000) // Run every 60 seconds

        return () => clearInterval(interval) // Cleanup on unmount
    }, [])

    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Activity Tracking</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='/home'>
                        Monitoring
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Activity Tracking</Breadcrumb.Item>
                </Breadcrumb>

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />


                        <Tabs
                            variant={'tabs'}
                            className='tabs '
                            id='controlled-tab-example'
                            activeKey={key}
                            onSelect={(k: any) => {
                                setKey(k)
                            }}
                            // sx={{
                            //   opacity: open ? 1 : 0,
                            //   fontWeight: openDropdown === 0 ? 700 : 100,
                            // }}
                        >
                            <Tab  eventKey='team-view' title='Team View'>
                                <Card className='border-0  p-0 bordered-card-tabs'>
                                    <Card.Body>
                                        <Row>
                                            <Col sm={6}>
                                                <span className='fw-bold'></span>
                                            </Col>
                                            <Col sm={6} className='d-flex justify-content-end hidden'>
                                                <InputGroup size='sm' className={'w-25'}>
                                                    <Form.Control
                                                        aria-label='search'
                                                        aria-describedby='inputGroup-sizing-sm'
                                                        placeholder='Search...'
                                                        value={searchedKeyword}
                                                        onChange={handleSearchedKeyword}
                                                    />
                                                    <InputGroup.Text className='bg-transparent search-input '>
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                employees.length > 0 ?
                                                    employees.map((employee, index) => (
                                                        <Col key={index} sm={3}>
                                                            <Card className='shadow bordered-card border-0 card-employee' onClick={() => {
                                                                getAllAttendanceSettings(employee.employee_id)
                                                                handleClickCard(employee.employee_attendance_id)
                                                            }
                                                            }>
                                                                <Card.Body style={{ padding: 0 }}>
                                                                    <Container fluid className='p-2'>

                                                                        <Row style={{fontSize: '85%'}}>
                                                                            <Col sm={10}>
                                                                                <Stack direction='horizontal'>
                                                                                    <div>
                                                                                        {
                                                                                            employee.photo != "" ?
                                                                                                <Avatar
                                                                                                    sx={{ width: 50, height: 50 }}
                                                                                                    src={process.env.REACT_APP_IMAGE_URL+ employee.photo}
                                                                                                ></Avatar> :
                                                                                            <Avatar
                                                                                                sx={{ width: 50, height: 50}}
                                                                                                src={""}
                                                                                            ></Avatar>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='mx-3'>
                                                                                        &nbsp;{employee.first_name} {employee.last_name}
                                                                                        <br />
                                                                                        &nbsp;<span style={{fontSize: '90%'}} className='text-muted fw-light'>{employee.designation_name}</span>
                                                                                    </div>

                                                                                </Stack>
                                                                            </Col>
                                                                            <Col sm={2} className="d-flex align-items-center justify-content-center">
                                                                                <div
                                                                                    title={employee.out_time ? "Offline" : "Online" }
                                                                                    style={{
                                                                                        width: '20px',
                                                                                        height: '20px',
                                                                                        backgroundColor: employee.out_time ? "gray" : "#82C341" ,
                                                                                        borderRadius: "50%",
                                                                                        marginTop: '-20px'
                                                                                    }}
                                                                                ></div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className={'text-center'} style={{fontSize: '75%'}}>
                                                                            <Col className='fw-bold display-ellipsis' sm={12} title={employee.recent_viewed_site}>
                                                                                {employee.recent_viewed_site}
                                                                                <br />
                                                                                <span style={{fontSize: '90%'}} className='text-muted fw-light'>Recent Visit</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <hr style={{marginTop: '3px', marginBottom: '3px'}}/>
                                                                        <Row className={'text-center'} style={{fontSize: '75%'}}>
                                                                            <Col className='fw-bold' sm={12}>
                                                                                {employee.hours_worked} hrs.
                                                                                <br />
                                                                                <span style={{fontSize: '90%'}} className='text-muted fw-light'>Hours Worked Including Passive</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <hr style={{marginTop: '3px', marginBottom: '3px'}}/>
                                                                        <Row className={'text-center'} style={{fontSize: '75%'}}>
                                                                            <Col className='fw-bold display-ellipsis' sm={12} title={employee.top_application_visited}>
                                                                                {employee.top_application_visited}
                                                                                <br />
                                                                                <span style={{fontSize: '90%'}} className='text-muted fw-light'>Top Application Visited</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <hr style={{marginTop: '3px', marginBottom: '3px'}}/>
                                                                        <Row >
                                                                            <Col sm={12} className='fw-bold text-center'>
                                                                                {
                                                                                    handleDisplayActivityLevel(employee.activity_level)
                                                                                }
                                                                                <br />
                                                                                <span style={{fontSize: '70%'}} className='text-muted fw-light'>Productivity</span>
                                                                            </Col>
                                                                        </Row>

                                                                        {/*</Table>*/}
                                                                    </Container>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                    : <Col sm={12} className={'text-center'}>No Employee/s that is/are present yet.</Col>
                                            }

                                        </Row>
                                        <br/>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey='screen-view' title='Screen View'>
                                <Card className='border-0  p-0 bordered-card-tabs'>
                                    <Card.Body>
                                        <Row>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Tab>
                        </Tabs>

                        <Loader show={showLoading} />


                {/* ACTIVITY TIME MODAL*/}
                <Modal className='modal' size="lg" centered show={showActivityTime} onHide={handleCloseActivityTime}>
                    <Modal.Header closeButton >
                        <Modal.Title className='fw-bold text-center'>Activity Time and Screenshots</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={4}></Col>
                            <Col sm={4} className={'text-center'}>
                                <p className={'fw-bold'}>{modalTitle}</p>
                            </Col>
                            <Col sm={4}>
                                <div className={'d-flex justify-content-end'}>
                                    <ButtonToolbar>

                                        <ToggleButtonGroup
                                            className="switch-view"
                                            type="radio"
                                            name="option-views"
                                            value={"default"}
                                            // onChange={(selected: any) => setView(selected)}
                                        >
                                            <ToggleButton onClick={() => {
                                                setView("pie-chart")
                                                setModalTitle("Pie Chart")
                                            }} title={"Pie Chart"} style={{backgroundColor: view == "pie-chart" ? '#ff7043' : 'white'}}  value={"pie-chart"}>{view == "pie-chart" ? <PieChartWhiteIcon/> : <PieChartIcon/>}</ToggleButton>
                                            <ToggleButton onClick={() => {
                                                setView("list-chart")
                                                setModalTitle("List Chart")
                                            }} title={"List Chart"} style={{backgroundColor: view == "list-chart" ? '#ff7043' : 'white'}}  value={"list-chart"}>{view == "list-chart" ? <TableListWhiteIcon/> : <TableListIcon/>}</ToggleButton>
                                            <ToggleButton onClick={() => {
                                                setView("screenshot")
                                                setModalTitle("Screenshots")
                                            }} title={"Screenshot"} style={{backgroundColor: view == "screenshot" ? '#ff7043' : 'white'}}  value={"screenshot"}>{view == "screenshot" ? <ScreenshotIcon color={"white"}/> : <ScreenshotIcon color={"#a7a9ac"}/>}</ToggleButton>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                </div>
                            </Col>
                        </Row>
                        {
                            view == "screenshot" ?
                                <Row>
                                    {
                                        attendanceSettings.takeScreenshot == 1 ?
                                            screenshots.length > 0 ?
                                                screenshots.map((screenshot, index) => (
                                                    <Form.Group as={Col} key={index} md="3" style={{padding:"10px"}}>
                                                        <a href={IMAGE_BASE_URL + screenshot.path} target="_blank" className="photo-a" rel="noreferrer">
                                                            <div className="card" >
                                                                <div className="card-body d-flex flex-column" style={{ paddingTop: '3px', paddingBottom: '3px', paddingLeft: '5px'}}>
                                                                    <Row>
                                                                        <Col sm={10} title={screenshot.title}>
                                                                            <p className="card-text display-title" style={{color:"black"}}><span style={{fontSize: '70%'}}>{screenshot.created_at ? moment(screenshot.created_at).format(localStorage.getItem('time_format') ?? "") : ""}</span> &nbsp;&nbsp;&nbsp;<b style={{fontSize: '80%'}}>{screenshot.title}</b></p>
                                                                        </Col>
                                                                        {
                                                                            attendanceSettings.trackMouseKeyboardActivity == 1 ?
                                                                                <Col sm={2} style={{margin: 'auto'}}>{displayPie(screenshot.percentage)}</Col>
                                                                                : ""
                                                                        }

                                                                    </Row>

                                                                </div>
                                                                <img style={{height: "130px", width: "auto", objectFit: "cover"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + screenshot.path}/>
                                                            </div>
                                                        </a>
                                                    </Form.Group>

                                                ))

                                                : <p className={'text-center'}>No Screenshot yet.</p>
                                            : <p className={'text-center'}>Screenshot is disabled for the employee.</p>
                                    }
                                </Row>
                                :
                                <Row>
                                    <Col sm={4}>
                                        {
                                            attendanceSettings.trackMouseKeyboardActivity == 1 ?
                                                <>
                                                    <Table responsive size='sm'>
                                                        <thead >
                                                        <tr>
                                                            <th className='text-center' style={{width: '70%'}}>Time</th>
                                                            <th className='text-center' style={{width: '30%'}}>Activity</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            activityTime.length > 0 ?
                                                                <>
                                                                    {
                                                                        activityTime.map((act, index) => (
                                                                            <tr key={index}>
                                                                                <td className={'text-center'} onClick={(e: any) => {
                                                                                    handleShowApplication(e, act.start_time, act.end_time)
                                                                                    setActivityLevel(`${act.activity_percentage} %`)
                                                                                }}>
                                                                                    <span className={timeHighlight == act.start_time ? 'highlight-time-display' : 'time-display'}>{moment(act.start_time).format(localStorage.getItem('time_format') ?? "hh:mm:ss A") +' - '+ moment(act.end_time).format(localStorage.getItem('time_format') ?? "hh:mm:ss A")}</span>
                                                                                </td>
                                                                                <td onClick={(e: any) => {
                                                                                    handleShowApplication(e, act.start_time, act.end_time)
                                                                                    setActivityLevel(`${act.activity_percentage} %`)
                                                                                }} className='text-center'>
                                                                                    <span className={timeHighlight == act.start_time ? 'highlight-time-display' : 'time-display'}>{act.activity_percentage} %</span>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    <tr>
                                                                        <td onClick={() => {
                                                                            setTimeHighlight("average")
                                                                            handleClickCard(selectedEmployeeAttendance)
                                                                            setActivityLevel(getAveragePercentage())
                                                                        }} className={'text-center fw-bold'}>
                                                                            <span className={timeHighlight == "average" ? 'highlight-time-display' : 'time-display'}>Average</span>
                                                                        </td>
                                                                        <td onClick={() => {
                                                                            setTimeHighlight("average")
                                                                            handleClickCard(selectedEmployeeAttendance)
                                                                            setActivityLevel(getAveragePercentage())
                                                                        }} className={'text-center fw-bold'}>
                                                                            <span className={timeHighlight == "average" ? 'highlight-time-display' : 'time-display'}>{getAveragePercentage()}</span>
                                                                        </td>
                                                                    </tr>
                                                                </>

                                                                : <tr>
                                                                    <td className='text-center' colSpan={2}>No Attendance Activity.</td>
                                                                </tr>
                                                        }

                                                        </tbody>
                                                    </Table>
                                                    <p style={{fontSize: '80%'}}>Note: Click a specific time to view the apps and urls for that time frame.</p>
                                                </>
                                                : <p className={'text-center'}>Tracking of activity level is disabled for the employee.</p>
                                        }

                                    </Col>
                                    <Col sm={8}>
                                        <h6 className={'text-center fw-bold'}>Apps</h6>
                                        {
                                            attendanceSettings.trackAppUrl == 1 ?
                                                view == "pie-chart" ?
                                                    <PieChart applications={applications} percentage={percentage} activityLevel={activityLevel == "" ? getAveragePercentage() : activityLevel}/> :
                                                    <Table responsive size='sm'>
                                                        <thead >
                                                        <tr>
                                                            <th className='text-center' style={{width: '10%'}}></th>
                                                            <th className='text-center' style={{width: '65%'}}>Application</th>
                                                            <th className='text-center' style={{width: '35%'}}>Percentage</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            applications.length > 0 ?
                                                                <>
                                                                    {
                                                                        applications.map((app, index) => (
                                                                            <tr key={index}>
                                                                                <td className='text-left'>{index + 1}</td>
                                                                                <td className='text-left'>{app}</td>
                                                                                <td className='text-center'>{percentage[index]} %</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </>

                                                                : <tr>
                                                                    <td className='text-center' colSpan={3}>No Activity.</td>
                                                                </tr>
                                                        }

                                                        </tbody>
                                                    </Table>
                                                : <p className={'text-center'}>Tracking of app and url is disabled for the employee</p>
                                        }

                                        <h6 className={'mt-2 text-center fw-bold'}>URLs</h6>
                                        {
                                            attendanceSettings.trackAppUrl == 1 ?
                                                view == "pie-chart" ?
                                                    <PieChart applications={sites} percentage={sitesPercentage} activityLevel={activityLevel == "" ? getAveragePercentage() : activityLevel}/> :
                                                    <Table responsive size='sm'>
                                                        <thead >
                                                        <tr>
                                                            <th className='text-center' style={{width: '10%'}}></th>
                                                            <th className='text-center' style={{width: '65%'}}>Site</th>
                                                            <th className='text-center' style={{width: '25%'}}>Percentage</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            sites.length > 0 ?
                                                                <>
                                                                    {
                                                                        sites.map((site, index) => (
                                                                            <tr key={index}>
                                                                                <td className='text-left'>{index + 1}</td>
                                                                                <td className='text-left'>{site}</td>
                                                                                <td className='text-center'>{sitesPercentage[index]} %</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </>

                                                                : <tr>
                                                                    <td className='text-center' colSpan={3}>No Activity.</td>
                                                                </tr>
                                                        }

                                                        </tbody>
                                                    </Table>
                                                : <p className={'text-center'}>Tracking of app and url is disabled for the employee</p>
                                        }

                                    </Col>
                                </Row>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={handleCloseActivityTime}
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        </>
    )
}
